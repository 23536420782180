import { Avatar } from 'antd';
import { type FC } from 'react';
import hospitalLogo from '../../assets/logo-bvq4.png';
import { useAppSelector } from '../../redux/hooks';
import './_TabBar.scss';

export const TabBar: FC = () => {
  const vnDayOfWeek = [
    'Thứ Hai',
    'Thứ Ba',
    'Thứ Tư',
    'Thứ Năm',
    'Thứ Sáu',
    'Thứ Bảy',
    'Chủ Nhật',
  ];
  const today = new Date();
  const hospital = useAppSelector((state) => state.hospital);
  return (
    <div className="tabbar-root">
      <div className="tabbar-heading">
        {/* <RxCalendar size={30} /> */}
        <h4>{`${vnDayOfWeek[today.getDay()]}, ${today.getDate() + 1} 
        tháng ${today.getMonth() + 1}`}</h4>
      </div>
      <div className="tabbar-user-info">
        <h4>{hospital.name}</h4>
        <Avatar size={40} src={hospitalLogo} />
      </div>
    </div>
  );
}
