import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type DeptProfile, type HospitalProfile } from '../utils/types';
import { type RootState } from './store';

export const initialState: HospitalProfile = {
  name: '',
  id: '',
  departments: [],
  addr: '',
  phone: '',
  logo: '',
  email: '',
};

export const hospitalSlice = createSlice({
  name: 'hospital',
  initialState,
  // The `reducers` field lets us define reducers and generate associated action
  reducers: {
    loginUser: (state, action: PayloadAction<HospitalProfile>) => {
      const data = action.payload;
      state.name = data.name;
      state.id = data.id;
      state.addr = data.addr;
      state.phone = data.phone;
      state.logo = data.logo;
      state.viewPaidPassword = data.viewPaidPassword;

      // TODO: use forEach instead, has to figure out that bug first.
      // Object.keys(action.payload).forEach((hospitalCred) => {
      // });
    },
    logoutUser: () => initialState,
    loadDept: (state, action: PayloadAction<DeptProfile[]>) => {
      state.departments = action.payload;
    },
  },
});

export const { loginUser, logoutUser, loadDept } = hospitalSlice.actions;

export const selectUser = (state: RootState): HospitalProfile => state.hospital;

export default hospitalSlice.reducer;
