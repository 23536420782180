import { Col, Empty, notification, Popconfirm, Row, Tag } from 'antd';
import { useEffect, useState, type FC } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import {
  createServiceInFirebase,
  deleteServiceFromFirebase,
  loadServicesFromFirebase,
  updateServiceInFirebase,
  type Service
} from '../../model/service';
import { defaultNotificationConfig } from '../../service/notification';
import { toVNDString } from '../../utils/utils';
import './_ServiceMgr.scss';
import ServiceInfoModal from './ServiceInfoModal';

interface ServiceMgrProps {
  deptId: string;
}

export const ServiceMgr: FC<ServiceMgrProps> = ({
  deptId,
}: ServiceMgrProps) => {
  const [serviceList, setServiceList] = useState<Service[]>([]);

  useEffect(() => {
    void loadServiceList();
  }, [deptId]);

  const loadServiceList = async () => {
    await loadServicesFromFirebase(deptId)
      .then((serviceList) => {
        notification.success(
          defaultNotificationConfig('Tải loại khám thành công!')
        );
        setServiceList(serviceList);
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Tải loại khám thất bại!')
        );
      });
  };

  const addService = async (service: Service) => {
    await createServiceInFirebase(deptId, service)
      .then((serviceId) => {
        notification.success(
          defaultNotificationConfig('Thêm loại khám thành công!')
        );
        void loadServiceList();
      })
      .catch((err) => {
        console.error(err);
        notification.error(
          defaultNotificationConfig('Thêm loại khám thất bại')
        );
      });
  };

  const updateService = async (service: Service) => {
    await updateServiceInFirebase(deptId, service)
      .then(() => {
        notification.success(
          defaultNotificationConfig('Cập nhật loại khám thành công!')
        );
        void loadServiceList();
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Cập nhật loại khám thất bại')
        );
      });
  };

  /**
   * Delets the specified service on firestore
   *
   * @param service the {@link Service} that will be deleted on Firebase
   */
  const deleteService = async (service: Service) => {
    if (service.id != null) {
      await deleteServiceFromFirebase(deptId, service.id)
        .then(() => {
          notification.success(
            defaultNotificationConfig('Xóa loại khám thành công!')
          );
          void loadServiceList();
        })
        .catch(() => {
          notification.error(
            defaultNotificationConfig('Xóa loại khám thất bại')
          );
        });
    }
  };

  return (
    <div className="service-card">
      <Row className="service-card-title heading-div">
        <h4>Loại khám</h4>
        <ServiceInfoModal
          deptId={deptId}
          updateService={updateService}
          addService={addService}
        />
      </Row>
      <div className="service-card-wrapper">
        <Row className="service-card-legend">
          <Col span={14}>
            <h5>Tên loại khám</h5>
          </Col>
          <Col span={7}>
            <h5 className="service-card-col">Chức năng</h5>
          </Col>
          <Col span={3}>
            <h5>Tác vụ</h5>
          </Col>
        </Row>
        <div className="service-card-list">
          {serviceList.length === 0 ? (
            <Empty description={"Chưa có loại khám, xin hãy thêm loại khám"} />
          ) : (
            serviceList.map((service, i) => (
              <ServiceCard
                deptId={deptId}
                key={`service-${i}`}
                service={service}
                index={i}
                deleteService={deleteService}
                updateService={updateService}
                addService={addService}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

interface ServiceCardProps {
  deptId: string;
  service: Service;
  index: number;
  deleteService: (service: Service) => Promise<void>;
  updateService: (service: Service) => Promise<void>;
  addService: (service: Service) => Promise<void>;
}

const ServiceCard: FC<ServiceCardProps> = ({
  deptId,
  service,
  index,
  deleteService,
  updateService,
  addService,
}: ServiceCardProps) => (
  <Row className="service-card-root">
    <Col span={14}>
      <p>{service.name}</p>
      <p>{toVNDString(service.price)}</p>
    </Col>
    <Col className="service-card-col" span={7}>
      {service.isWithDoctor ? <Tag color="cyan">
        BS
      </Tag> : null}
      {service.isWithInsurance ? <Tag color="magenta">
        BHYT
      </Tag> : null}
      {!service.isWithDoctor && !service.isWithInsurance
        ? <Tag color="yellow">
          Không có
        </Tag> : null}
    </Col>
    <Col span={1}>
      <Popconfirm
        title={`Xóa loại khám ${service.name}?\n
          (Lưu ý: đây là xóa vĩnh viễn!)`}
        className="scheduler-col-delete-btn"
        onConfirm={async () => {
          await deleteService(service);
        }}>
        <BsFillTrashFill size={15} className="service-card-delete" />
      </Popconfirm>
    </Col>
    <Col span={1}>
      <ServiceInfoModal
        deptId={deptId}
        serviceInfo={service}
        updateService={updateService}
        addService={addService}
      ></ServiceInfoModal>
    </Col>
  </Row>
);
