import { createSlice } from '@reduxjs/toolkit';

// Add more categories if needed
const initialState = {
  isPageNotFound: false,
};

export const pageNotFoundSlice = createSlice({
  name: 'pageNotFound',
  initialState,
  reducers: {
    changePageNotFound: (state, action) => {
      state.isPageNotFound = action.payload;
    },
  },
});

export const { changePageNotFound } = pageNotFoundSlice.actions;

export default pageNotFoundSlice.reducer;
