import { doc, setDoc } from '@firebase/firestore';
import {
  Col, notification, Popconfirm, Row, Tag, Tooltip
} from 'antd';
import moment from 'moment';
import { useEffect, useState, type FC } from 'react';
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import { ApptStatus, type AppointmentInfo } from '../../model/appointment';
import { getDoctorNameById } from '../../model/doctor';
import { defaultNotificationConfig } from '../../service/notification';
import { GRACE_PERIOD_IN_MINUTES } from '../../utils/constants';
import { getCurrentMilliseconds } from '../../utils/datetime_utils';
import { db } from '../../utils/firebase';
import { getDiffMinutes, toVNDString } from '../../utils/utils';
import CancelModal from './CancelModal';
import './_AppointmentCard.scss';

interface AppointmentCardProps {
  showPaidAmount?: boolean;
  deptId: string;
  appt: AppointmentInfo;
  index: number;
  showExtraDetails?: boolean;
}

export const AppointmentCard: FC<AppointmentCardProps> = ({
  showPaidAmount = false,
  deptId,
  appt,
  index,
  showExtraDetails = false,
}: AppointmentCardProps) => {
  const today = appt.apptTime;
  const statusUpdatedStr = appt.statusUpdatedTime.toDateTimeString();
  const timeStr = moment(appt.apptTime).toTimeString();

  const [isCancelModalVisible, toggleCancelModalVisible] = useState(false);
  const [isReasonModalVisible, toggleReasonModalVisible] = useState(false);
  const [doctorName, setDoctorName] = useState(undefined);

  const tooltipPlacement = 'bottom';

  useEffect(() => {
    if (appt.doctorId != null) {
      void getDoctorNameById(deptId, appt.doctorId).then((docName) => {
        setDoctorName(docName);
      });
    }
  });

  // This is for turning on and off cancel modal, put here for convenience
  const toggleCancelModal = () => {
    toggleCancelModalVisible((prevStatus) => !prevStatus);
  };

  // This is for turning on and off the change cancel reason modal
  const toggleReasonModal = () => {
    toggleReasonModalVisible((prevStatus) => !prevStatus);
  };

  /**
   * Adds "cancelled" status to this appointment with the provided cancellation
   * reason
   *
   * @param cancelReason the reason to cancel, required
   */
  const staleAppointment = async () => {
    const apptRef = doc(db, 'appointment', appt.id);
    await setDoc(
      apptRef,
      {
        status: ApptStatus.stale,
        cancelReason: `Tự động hủy ${GRACE_PERIOD_IN_MINUTES} phút sau giờ hẹn`,
        statusUpdatedTime: getCurrentMilliseconds(),
      },
      { merge: true }
    );
  };

  // If current time is past the grace period and still pending with no, then
  // cancel right away
  if (
    appt.status === ApptStatus.pending &&
    getDiffMinutes(today, moment()) >= GRACE_PERIOD_IN_MINUTES
  ) {
    appt.status = ApptStatus.stale;
    // appt.statusUpdatedTime = moment();
    void staleAppointment();
    return <></>;
  }

  /**
   * Adds completed status to this appointment
   */
  const completeAppointment = async () => {
    const apptRef = doc(db, 'appointment', appt.id);
    await setDoc(
      apptRef,
      {
        status: ApptStatus.completed,
        cancelReason: null,
        statusUpdatedTime: getCurrentMilliseconds(),
      },
      { merge: true }
    )
      .then(() => {
        notification.success(
          defaultNotificationConfig('Cập nhập đã khám thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Cập nhập đã khám không thành công!')
        );
      });
  };

  /**
   * Adds "cancelled" status to this appointment with the provided cancellation
   * reason
   *
   * @param cancelReason the reason to cancel, required
   */
  const cancelAppointment = async (cancelReason: string) => {
    const apptRef = doc(db, 'appointment', appt.id);
    await setDoc(
      apptRef,
      {
        status: ApptStatus.cancelled,
        cancelReason,
        statusUpdatedTime: getCurrentMilliseconds(),
      },
      { merge: true }
    )
      .then(() => {
        notification.success(
          defaultNotificationConfig('Cập nhập hủy khám thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Cập nhập hủy khám không thành công!')
        );
      });
  };

  /**
   * Undo the "completed" status of this appointment, changes it to "pending"
   */
  const undoCompleteAppointment = async () => {
    const apptRef = doc(db, 'appointment', appt.id);
    await setDoc(
      apptRef,
      {
        status: ApptStatus.pending,
        cancelReason: null,
        statusUpdatedTime: getCurrentMilliseconds(),
      },
      { merge: true }
    )
      .then(() => {
        notification.success(
          defaultNotificationConfig('Cập nhập bỏ đã khám thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Cập nhập bỏ đã khám không thành công!')
        );
      });
  };

  /**
   * Status switch component
   *
   * @param status the status of the current Appointment
   * @returns the corresponding React component
   */
  const statusSwitch = (status: ApptStatus) => {
    switch (status) {
      case ApptStatus.completed:
        // handles completed status
        return (
          <Tooltip
            title={`Đã khám vào ${statusUpdatedStr}!\n`}
            placement={tooltipPlacement}
          >
            <Popconfirm
              title="Bạn có chắc muốn hủy trạng thái đã khám?"
              onConfirm={undoCompleteAppointment}>
              <Tag
                color="green"
                className="appt-card-status-tag"
              >
                Đã khám
              </Tag>
            </Popconfirm>
          </Tooltip>
        );
      case ApptStatus.cancelled:
      case ApptStatus.stale:
        // handles cancelled and stale status
        return (
          <Row gutter={10} className="appt-card-cancel-status">
            <Tooltip
              title={`Đã hủy vào ${statusUpdatedStr}\n`}
              placement={tooltipPlacement}
            >
              <Tag
                onClick={toggleReasonModal}
                color="volcano"
                className="appt-card-status-tag"
              >
                Đã hủy
              </Tag>
            </Tooltip>
            <CancelModal
              cancelAppointment={cancelAppointment}
              isVisible={isReasonModalVisible}
              toggleModal={toggleReasonModal}
              cancelReason={appt.cancelReason}
            />
          </Row>
        );
      case ApptStatus.pending:
      default:
        // handles pending status as well as any other status
        return (
          <>
            <Col>
              <Tooltip title="Xác nhận đã khám" placement={tooltipPlacement}>
                <HiOutlineCheckCircle
                  size={22}
                  className="complete-appt-btn status-change-btn"
                  onClick={completeAppointment} />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip
                title="Xác nhận hủy khám (phải ghi lý do!)"
                placement={tooltipPlacement}
              >
                <HiOutlineXCircle
                  size={22}
                  className="cancel-appt-btn status-change-btn"
                  onClick={() => {
                    toggleCancelModal();
                  }} />

              </Tooltip>
              <CancelModal
                cancelAppointment={cancelAppointment}
                isVisible={isCancelModalVisible}
                toggleModal={toggleCancelModal}
              />
            </Col>
          </>
        );
    }
  };

  return (
    <div className={"appt-card-root"} key={index}>
      {!showExtraDetails
        ? <Row className="appt-card-main-details">
          <Col span={4}>
            <p>{appt.name}</p>
          </Col>
          <Col span={2} className="centered">
            <p>{appt.age}</p>
          </Col>
          <Col span={3}>
            <p>{appt.bhyt}</p>
          </Col>
          <Col span={4} >
            <p>{appt.service != null ? appt.service.name : 'BHYT'}</p>
          </Col>
          <Col span={4} className="appt-card-center-info centered">
            <p>{appt.sttBhyt != null ? appt.sttBhyt : timeStr}</p>
          </Col>
          <Col span={4} >
            <p>{appt.paymentStatus}  {appt.service != null &&
              showPaidAmount && (
                <p>
                  {toVNDString(appt.service.price)}
                </p>
            )}</p>
          </Col>
          <Col span={2} className="appt-card-status centered">
            {statusSwitch(appt.status)}
          </Col>
        </Row>
        : <Row className="appt-card-extra-details">
          <Row className="extra-details-row">
            <Col span={4}><h5>SDT</h5></Col>
            <Col span={4}><h5>Địa chỉ</h5></Col>
            <Col span={4}><h5>Email</h5></Col>
            <Col span={4}><h5>CMND</h5></Col>
            <Col span={4}><h5>Ngày sinh</h5></Col>
            <Col span={4}><h5>Bác sĩ</h5></Col>
          </Row>
          <Row className="extra-details-row">
            <Col span={4}>{appt.phone}</Col>
            <Col span={4}>{appt.addr}</Col>
            <Col span={4}>{appt.email}</Col>
            <Col span={4}>{appt.cmnd}</Col>
            <Col span={4}>{appt.dateOfBirth}</Col>
            <Col span={4}>{doctorName}</Col>
          </Row>
        </Row>
      }
    </div>
  );
};
