import {
  configureStore,
  type ThunkAction,
  type Action,
  combineReducers,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import hospitalReducer from './hospitalSlice';
import pageNotFoundReducer from './pageNotFoundSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  hospital: hospitalReducer,
  pageNotFound: pageNotFoundReducer,
});

const persistConfig = {
  key: 'root',
  blacklist: ['pageNotFound'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
