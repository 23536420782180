import { Col, Row } from 'antd';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';
import CDSidebar from '../../component/CDSidebar';
import DoctorScheduler from '../../component/DoctorScheduler';
import TabBar from '../../component/TabBar';
import './_WeeklySchedule.scss';

export const WeeklySchedule: FC = () => {
  const { deptId } = useParams();

  return (
    <div className="dept-root dashboard-inner">
      <Row>
        <Col sm={6} lg={4} xl={3}>
          <CDSidebar />
        </Col>
        <Col sm={18} lg={20} xl={21}>
          <TabBar />
          <div className="dept-doc-view-root">
            <DoctorScheduler deptId={deptId!} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
