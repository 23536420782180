import { Col, Tag } from 'antd';
import { type FC, type ReactNode } from 'react';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import './_SummaryTile.scss';

interface SummaryTileProps {
  bigDisplay: string | number;
  smallDisplay: string;
  icon: ReactNode;
  statDiff?: number;
}

export const SummaryTile: FC<SummaryTileProps> = ({
  bigDisplay,
  smallDisplay,
  icon,
  statDiff
}: SummaryTileProps) => {
  return (
    <Col className="summary-tile">
      <div className="headings">
        <div className="icon-cover">
          {icon}
        </div>
        <div>
          <h2>{bigDisplay}</h2>
          <p>{smallDisplay}</p>
        </div>
      </div>
      {statDiff !== null ? <div className="status-display">
        <Tag color={statDiff! < 0 ? "red" : "green"}>
          {statDiff! < 0 ? <BsArrowDownRight size={12} />
            : <BsArrowUpRight size={12} />}
          {Math.abs(statDiff!)} so với hôm qua
        </Tag>
      </div> : null}
    </Col>
  );
};
