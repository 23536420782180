import type { MenuProps } from 'antd';
import {
  Breadcrumb, Button,
  Col,
  DatePicker,
  Dropdown,
  notification,
  Popconfirm, Row, Select
} from 'antd';
import { getDocs, query, where } from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useState, type FC } from 'react';
import {
  deleteDoctorFromFirebase,
  loadDoctorsFromFirebase,
  type Doctor,
  type DoctorKey
} from '../../model/doctor';
import {
  deleteScheduleFromFirebase,
  loadScheduleFromFirebase,
  type Schedule
} from '../../model/schedule';
import {
  assignmentCol,
  createAssignmentOnFirebase,
  processAssignmentDataFromFirebase,
  updateAssignmentOnFirebase,
  type Assignment,
  type WeeklyAssignment
} from '../../model/weekly-assignment';
import { getDeptNameById } from '../../service/database';
import { defaultNotificationConfig } from '../../service/notification';
import AddDoctorModal from './AddDoctorModal';
import AddScheduleModal from './AddScheduleModal';
import AssignmentViewer from './AssignmentViewer';
import './_DoctorScheduler.scss';
interface DoctorSchedulerProps {
  deptId: string;
}

export const DoctorScheduler: FC<DoctorSchedulerProps> = ({
  deptId,
}: DoctorSchedulerProps) => {
  // the schedule list of the specified department
  const [schedList, setSchedList] = useState<Schedule[]>([]);

  // the index of the schedule that we want to view
  // if it's -1, then nothing is chosen yet
  const [schedIdx, setSchedIdx] = useState<number>(-1);

  // The list of the doctor
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);

  // The map of the doctor
  const [doctorMap, setDoctorMap] = useState<DoctorKey>({});

  // Check whether we're loading from the database
  const [isLoading, setLoading] = useState(true);

  // Check whether there has been a new change yet, to prevent mindless saving
  const [isNewChange, setNewChange] = useState(false);

  // Check whether we're updating the assignment
  const [isUpdating, setUpdating] = useState(false);

  // Check if we're reloading
  // TODO: I should think of a better system for these checks...
  const [isReloading, setReloading] = useState(false);

  // The current assignment of the week
  const [curSchedule, setCurSchedule] = useState<WeeklyAssignment | null>(null);

  // name of dept
  const [deptName, setDeptName] = useState('');

  // holding a moment object of the picked week
  const [startDate, setStartDate] = useState<moment.Moment>(moment());

  // picked schedule id
  const [scheduleId, setScheduleId] = useState('');

  // picked schedule name
  const [scheduleName, setScheduleName] = useState('');

  useEffect(() => {
    setSchedIdx(-1);
    void loadDoctorList();
    void loadScheduleList();
    getDeptNameById(deptId)
      .then((res) => {
        setDeptName(res);
      })
      .catch(() => { });
  }, [deptId]);

  /**
   * This is to load the doctors of this particular department
   */
  const loadDoctorList = async (schedId?: string) => {
    // if schedId is provided, then load that instead
    if (schedId != null) {
      const doctorMap: DoctorKey = {};
      await loadDoctorsFromFirebase(deptId, schedId)
        .then((doctorList) => {
          doctorList.forEach((doctor) => {
            doctorMap[doctor.id!] = doctor;
          });
          setDoctorList(doctorList);
          setDoctorMap(doctorMap);
          notification.success(
            defaultNotificationConfig('Tải danh sách bác sĩ thành công!')
          );
        })
        .catch(() => {
          notification.error(
            defaultNotificationConfig('Tải danh sách bác sĩ thất bại')
          );
        });
    } else {
      // resets otherwise
      setDoctorList([]);
      setDoctorMap({});
    }
  };

  /**
   * Handles deleting the specified {@link doctor}.
   *
   * @param doctor the doctor that will be deleted
   */
  const deleteDoctor = async (doctor: Doctor) => {
    await deleteDoctorFromFirebase(deptId, doctor.id!)
      .then(() => {
        notification.success(
          defaultNotificationConfig('Xóa bác sĩ thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Xóa bác sĩ không thành công')
        );
      });
    void loadDoctorList(schedList[schedIdx].id);
  };

  /**
   * Loads the schedule list of this department
   */
  const loadScheduleList = async () => {
    const scheduleList = await loadScheduleFromFirebase(deptId);
    setSchedList(scheduleList);
  };

  /**
   * The main driver of this assignment page.
   * This will be loading the entire assignment for the page
   *
   * @param wDate the date inside the week
   */
  const loadSchedAssignments = async (date: moment.Moment) => {
    setLoading(true);
    refreshAssignment();
    const weekStart = date.startOfWeek();
    const weekStartTime = weekStart.valueOf();

    const assignmentQuery = query(
      assignmentCol(deptId, schedList[schedIdx].id!),
      where('weekStart', '==', weekStartTime)
    );

    const querySnapshot = await getDocs(assignmentQuery);
    // if there exists a assignment (which should only be one anyway), then take
    if (querySnapshot.docs.length > 0) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      setCurSchedule(processAssignmentDataFromFirebase(data, doc.id));

      if (data.amAssignments !== undefined) {
        [...Array(5)].forEach(async (_: any, dayI: number) => {
          await loadAssignment(
            data.amAssignments[dayI],
            data.pmAssignments[dayI],
            dayI
          );
        });
      }
      notification.success(defaultNotificationConfig('Đã tải được lịch tuần!'));
    } else {
      // if not, create new assignment on firestore
      // const newSchedId = await createScheduleOnFirebase(deptId, weekStart);
      setCurSchedule({
        id: null,
        weekStart,
        amAssignments: [],
        pmAssignments: [],
      });
    }
    setLoading(false);
  };

  /**
   * Load the week assignment of each doctors inside doctorList.
   *
   * What I'm doing in this function
   *
   * @param asm The assignment
   * @param dayI the index of the day of week
   */
  const loadAssignment = async (
    amAsm: Assignment,
    pmAsm: Assignment,
    dayI: number
  ) => {
    for (const doctorId in amAsm) {
      doctorMap[doctorId].amAvail[dayI] = amAsm[doctorId];
    }
    for (const doctorId in pmAsm) {
      doctorMap[doctorId].pmAvail[dayI] = pmAsm[doctorId];
    }
  };

  /**
   * Refresh the assignment of each doctor to prepare for the next update
   */
  const refreshAssignment = () => {
    doctorList.forEach((doctor) => {
      for (let i = 0; i < 5; i++) {
        doctorMap[doctor.id!].amAvail[i] = false;
        doctorMap[doctor.id!].pmAvail[i] = false;
      }
    });
  };

  /**
   * Update the assignment to Firestore database.
   *
   * Method: We add the doctor's id to each of "mon"..."fri" collection, and if
   * that doctor is assigned, we will set amAvail or pmAvail to true/false.
   *
   * @param assignment The weekly assignment to be updated
   * @param deptId The id of the department that we wish to create
   */
  const updateSchedule = async (
    assignment: WeeklyAssignment,
    deptId: string
  ) => {
    const newAmAsm: Assignment[] = [];
    const newPmAsm: Assignment[] = [];

    for (let i = 0; i < 5; i++) {
      newAmAsm.push({});
      newPmAsm.push({});
    }

    doctorList.forEach((doctor) => {
      const amAvail = doctorMap[doctor.id!].amAvail;
      const pmAvail = doctorMap[doctor.id!].pmAvail;
      for (let i = 0; i < 5; i++) {
        if (amAvail[i]) newAmAsm[i][doctor.id!] = amAvail[i];
        if (pmAvail[i]) newPmAsm[i][doctor.id!] = pmAvail[i];
      }
    });

    // if id is null, we know that it's not on Firestore yet, so we create new
    if (assignment.id == null) {
      const newSchedId = await createAssignmentOnFirebase(
        deptId,
        schedList[schedIdx].id!,
        assignment.weekStart.valueOf(),
        newAmAsm,
        newPmAsm
      );
      setCurSchedule({
        id: newSchedId,
        weekStart: assignment.weekStart,
        amAssignments: newAmAsm,
        pmAssignments: newPmAsm,
      });
    } else {
      // if already exists, then just need to update assignment on Firestore
      await updateAssignmentOnFirebase(
        deptId,
        schedList[schedIdx].id!,
        assignment.id,
        newAmAsm,
        newPmAsm
      );
    }
  };

  /**
   * Preconditions to check if the btn is clickable to execute the things
   * inside the scheduler.
   *
   * @returns the button is clickable
   */
  const checkClickable = () => {
    if (curSchedule == null) {
      notification.error(defaultNotificationConfig('Bạn chưa chọn tuần!'));
      return false;
    }
    return true;
  };

  // configurations for the "ADD" button
  const onClick: MenuProps['onClick'] = ({ key }) => {
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <AddScheduleModal deptId={deptId}
        loadScheduleList={loadScheduleList} />
    },
    {
      key: '2',
      label: <AddDoctorModal
        deptId={deptId}
        schedId={scheduleId}
        checkClickable={checkClickable}
        loadDoctorList={loadDoctorList}
      />
    },
    {
      key: '3',
      label:
        <Popconfirm
          title={`Xóa nhóm lịch bác sĩ ${scheduleName}?`}
          className="scheduler-col-delete-btn"
          onConfirm={async () => {
            await deleteScheduleFromFirebase(deptId,
              scheduleId);
            await loadScheduleList();
            // reset schedIdx to -1 so it will reset the lịch bác sĩ select
            setSchedIdx(-1);
          }}
        >
          <Button className="dull-button danger-button">
            Xóa nhóm lịch này
          </Button>
        </Popconfirm>

    }
  ];
  return (
    <div className="scheduler-root">
      <div className="scheduler-view-breadcrumbs">
        <Breadcrumb separator=">" >
          <Breadcrumb.Item>
            Khoa
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {deptName}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Lịch bác sĩ
          </Breadcrumb.Item>
          {schedIdx !== -1
            ? <Breadcrumb.Item>
              {scheduleName}
            </Breadcrumb.Item> : null}
        </Breadcrumb>
      </div>
      <Col className="scheduler-view">
        <Row className="scheduler-week-picker-ctr">
        </Row>
        <>
          {curSchedule != null
            ? <Row className="last-updated-text">
              {curSchedule.updatedTime != null &&
                <em>Lần cuối cập nhật:
                  {curSchedule.updatedTime.toDateTimeString()}
                </em>}
            </Row> : null}
          <Row className="assignment-viewer-heading heading-div">
            <div className="assignment-viewer-heading-title">
              {schedIdx !== -1
                ? <h4>{`Nhóm lịch ${schedList[schedIdx].name}`}</h4>
                : <h4>Chọn nhóm lịch</h4>}
              <Select
                className="scheduler-week-picker"
                value={schedIdx === -1 ? null : schedIdx}
                onChange={(value) => {
                  // parse int value cuz for some reason Option does not support
                  // number type value, even though it said so in the docs
                  setSchedIdx(value);
                  setScheduleId(schedList[value].id!);
                  setScheduleName(schedList[value].name);
                  if (value != null) {
                    void loadDoctorList(schedList[value].id);
                  }
                }}
                placeholder="Chọn nhóm lịch"
              >
                {schedList.map((sched, schedI) => (
                  <Select.Option key={`sched-${schedI}`} value={schedI}>
                    {`${schedI + 1} - ${sched.name}`}
                  </Select.Option>
                ))}
              </Select>
              {schedIdx !== -1 &&
                <DatePicker
                  placeholder="Chọn tuần"
                  className="scheduler-week-picker"
                  picker="week"
                  onSelect={async (e) => {
                    setStartDate(moment(e.valueOf()));
                    await loadSchedAssignments(moment(e.valueOf()));
                  }} />}
            </div>
            {schedIdx !== -1 &&
              <div className="assignment-viewer-heading-functions">
                <Button
                  className="scheduler-update-assignment dull-button"
                  loading={isReloading}
                  onClick={async () => {
                    if (!checkClickable()) return;
                    setReloading(true);
                    if (curSchedule != null) {
                      await loadSchedAssignments(curSchedule.weekStart);
                    }
                    setReloading(false);
                  }}
                >
                  Tải lại
                </Button>
                <Button
                  className="scheduler-update-assignment dull-button"
                  loading={isUpdating}
                  onClick={async () => {
                    if (!checkClickable()) return;
                    setUpdating(true);
                    if (isNewChange && curSchedule != null) {
                      await updateSchedule(curSchedule, deptId);
                      setNewChange(false);
                      notification.success(
                        defaultNotificationConfig('Đã cập nhật!')
                      );
                    } else {
                      notification.error(
                        defaultNotificationConfig('Chưa có thay đổi mới!')
                      );
                    }
                    setUpdating(false);
                  }}
                >
                  Lưu thay đổi
                </Button>
                <Dropdown menu={{ items, onClick }} trigger={['click']}>
                  <Button type="primary"
                    onClick={(e) => { e.preventDefault() }}>
                    Quản lý lịch
                  </Button>
                </Dropdown>
              </div>}
          </Row>
          {curSchedule != null
            ? <AssignmentViewer
              isLoading={isLoading}
              doctorList={doctorList}
              deleteDoctor={deleteDoctor}
              setNewChange={setNewChange}
              startDate={startDate}
            />
            : <p>
              Bạn chưa chọn tuần. Hãy chọn nhóm lịch và một tuần
              để xem chi tiết lịch bác sĩ trong tuần đó.
            </p>
          }
        </>
      </Col>
    </div>
  );
};
