import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select
} from 'antd';
import { useEffect, useState, type FC } from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  loadScheduleFromFirebase,
  type Schedule
} from '../../../model/schedule';
import { type Service } from '../../../model/service';
import { defaultNotificationConfig } from '../../../service/notification';

interface ServiceInfoModalProps {
  deptId: string;
  serviceInfo?: Service;
  updateService: (service: Service) => Promise<void>;
  addService: (service: Service) => Promise<void>;
}

/**
 * This modal is to add/update service in the service types manager.
 */
export const ServiceInfoModal: FC<ServiceInfoModalProps> = ({
  deptId,
  serviceInfo,
  updateService,
  addService,
}: ServiceInfoModalProps) => {
  // Open and close for add doctor modal
  const [isVisible, toggleVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [schedList, setSchedList] = useState<Schedule[]>([]);
  // Add doctor form
  const [form] = Form.useForm();
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    loadScheduleFromFirebase(deptId)
      .then((schedList) => {
        setSchedList(schedList);
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Không tải được danh sách lịch bác sĩ')
        );
      });
  }, []);

  /**
   * Handles service submission request for both add and update
   */
  const handleServiceSubmit = async () => {
    // validating forms
    const values = await form.validateFields().catch((err) => {
      notification.error(defaultNotificationConfig('Kiểm tra lại thông tin!'));
      throw err;
    });
    // preparing new service, this applies for both add and update
    const newService: Service = {
      name: values.name,
      price: values.price,
      isWithDoctor: values.isWithDoctor,
      isWithInsurance: values.isWithInsurance,
      schedId: values.schedId,
    };
    // if serviceInfo and index is provided, then we want to update
    if (serviceInfo != null) {
      // a little hack to get the service id to be updated
      newService.id = serviceInfo.id;
      await updateService(newService);
    } else {
      await addService(newService);
    }
    // turn off modal
    toggleModal();
  };

  // This is for turning on and off add doctor modal, put here for convenience
  const toggleModal = () => {
    toggleVisible((prevStatus) => !prevStatus);
  };

  const toggleUpdating = () => {
    setUpdating((prevStatus) => !prevStatus);
  };

  // Open and close for add doctor modal
  return (
    <>
      {/* If serviceInfo populated, then editing, otherwise adding */}
      {serviceInfo == null ? (
        <Button
          className="scheduler-add-doctor-btn scheduler-viewer dull-button"
          onClick={toggleModal}
        >
          {serviceInfo == null ? 'Thêm loại khám' : 'Sửa'}
        </Button>
      ) : (
        <FaEdit className="service-card-edit" onClick={toggleModal} size={15}/>
      )}
      <Modal
        title={
          serviceInfo == null
            ? 'Thêm loại khám (lưu ý: chỉ được tối đa 10 loại khám)'
            : 'Sửa thông tin loại khám'
        }
        open={isVisible}
        onCancel={toggleModal}
        cancelText="Hủy"
        onOk={async () => {
          toggleUpdating();
          await handleServiceSubmit().catch(() => { });
          toggleUpdating();
        }}
        confirmLoading={isUpdating}
        destroyOnClose
      >
        <Form form={form} preserve={false}>
          <Form.Item
            name="name"
            label="Tên loại khám"
            labelCol={{ span: 8 }}
            initialValue={serviceInfo?.name ?? ''}
            tooltip={'Tên loại khám mà bạn muốn cho vào.'}
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập tên loại khám!',
              },
            ]}
            required
          >
            <Input
              maxLength={200}
              placeholder={'Dịch vụ (có BHYT)...'}
              required
            />
          </Form.Item>
          <Form.Item
            name="price"
            label="Thành tiền"
            labelCol={{ span: 8 }}
            initialValue={serviceInfo?.price ?? ''}
            tooltip={'Thành tiền của loại khám này.'}
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập thành tiền',
              },
            ]}
            required
          >
            <InputNumber
              maxLength={10}
              placeholder={'10000'}
              addonAfter="₫"
              decimalSeparator="."
              min={1000}
              controls={false}
              required
            />
          </Form.Item>
          <Form.Item
            name="isWithDoctor"
            label="Được chọn bác sĩ"
            labelCol={{ span: 8 }}
            valuePropName="checked"
            initialValue={serviceInfo?.isWithDoctor ?? false}
            tooltip={`Nếu bạn muốn bệnh nhân được chọn bác sĩ. Yêu cầu phải 
            có lịch hẹn bác sĩ hàng tuần.`}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              curValues.isWithDoctor !== prevValues.isWithDoctor
            }
          >
            {({ getFieldValue }) => {
              // eslint-disable-next-line no-extra-boolean-cast
              return Boolean(getFieldValue('isWithDoctor')) ? (
                <Form.Item
                  name="schedId"
                  label="Lịch bác sĩ"
                  labelCol={{ span: 8 }}
                  initialValue={serviceInfo?.schedId ?? undefined}
                  tooltip={`Nếu bạn muốn bệnh nhân được chọn bác sĩ. Yêu cầu
                  phải có lịch hẹn bác sĩ hàng tuần.`}
                  rules={[
                    {
                      required: true,
                      message: 'Bạn chưa chọn lịch bác sĩ!',
                    },
                  ]}
                >
                  <Select placeholder="Chọn lịch bác sĩ">
                    {schedList.map((sched, schedI) => (
                      <Select.Option key={`sched-${schedI}`} value={sched.id}>
                        {`${schedI + 1} - ${sched.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null;
            }}
          </Form.Item>

          <Form.Item
            name="isWithInsurance"
            label="Yêu cầu BHYT"
            labelCol={{ span: 8 }}
            valuePropName="checked"
            initialValue={serviceInfo?.isWithInsurance ?? false}
            tooltip={'Loại hình này yêu cầu phải có BHYT'}
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
