import {
  addDoc,
  collection, doc, getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  type DocumentData
} from "firebase/firestore";
import moment from "moment";
import { DEPT_DB, SCHEDULE_DB } from "../../utils/database";
import { getCurrentMilliseconds } from "../../utils/datetime_utils";
import { db } from "../../utils/firebase";

export interface Schedule {
  id?: string;
  name: string;
  addedTime: moment.Moment;
}

/**
 * Processes schedule data from Firestore to an instance of {@link Schedule}
 *
 * @param data data from Firestore
 * @param id the id of the schedule
 * @returns an instance of {@link Schedule} processed from given data
 */
export const processScheduleDataFromFirebase = (
  data: DocumentData,
  id: string
): Schedule => {
  const pkg: Schedule = {
    id,
    name: data.name ?? '[không có]',
    addedTime: moment(data.addedTime)
  }
  return pkg;
}

/**
 * Creates a schedule in the specified department with the {@link deptId}.
 *
 * @param deptId the id of the department to which you want to add the schedule
 * @param schedName name of new schedule
 * @returns the id of the newly created schedules
 */
export const createScheduleOnFirebase = async (
  deptId: string,
  schedName: string
): Promise<string> => {
  const schedDoc = await addDoc(scheduleCol(deptId), {
    name: schedName,
    addedTime: getCurrentMilliseconds(),
    isDeleted: false,
  });
  return schedDoc.id;
}

/**
 * Deletes the schedule with the id {@link schedId} from the department with
 * the id {@link deptId}. Note that this doesn't actually delete the schedule,
 * but just mark it as deleted.
 *
 * Due to the nested nature of our database, deleting the doc directly will
 * leave zombie children. There will be permanent deletion later implemented
 * for these scenarios.
 *
 * @param deptId the id of the department whose schedule you want to delete
 * @param schedId the id of the schedule which we want to delete
 */
export const deleteScheduleFromFirebase = async (
  deptId: string,
  schedId: string
): Promise<void> => {
  await updateDoc(doc(scheduleCol(deptId), schedId), {
    isDeleted: true,
  });
}

/**
 * Loads and return a schedule list from Firebase from the department specified
 * by the given {@link deptId}.
 *
 * @param deptId the id of the department from which we want to load the
 *  schedules from.
 * @returns the schedule list from the specified departments
 */
export const loadScheduleFromFirebase = async (deptId: string):
Promise<Schedule[]> => {
  const schedQuery = query(scheduleCol(deptId),
    where('isDeleted', '==', false),
    orderBy('addedTime'),
  );
  const schedDocs = await getDocs(schedQuery);
  const schedList: Schedule[] = [];
  schedDocs.forEach((schedDoc) => {
    const sched = processScheduleDataFromFirebase(schedDoc.data(), schedDoc.id);
    schedList.push(sched);
  })
  return schedList;
}

/**
 * Returns a collection reference of the schedule of the specified dept id.
 *
 * @param deptId the dept id whose schedule collection ref we want to load
 * @returns the collection reference inside the specified department
 */
export const scheduleCol = (deptId: string) =>
  collection(db, DEPT_DB, deptId, SCHEDULE_DB);
