import { Row, Col } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CDSidebar from '../../component/CDSidebar';
import ServiceMgr from '../../component/ServiceMgr';
import { getDeptNameById } from '../../service/database';
import './_Services.scss';

export const Services: FC = () => {
  const [deptName, setDeptName] = useState('');
  const { deptId } = useParams();

  useEffect(() => {
    void getDeptNameById(deptId!).then((res) => {
      setDeptName(res);
    });
  }, []);

  return (
    <div className="dept-root dashboard-inner">
      <Row>
        <Col sm={6} lg={4} xl={3}>
          <CDSidebar />
        </Col>
        <Col sm={18} lg={20} xl={15}>
          <div className="appt-view-root">
            <div className="appt-view-intro">
              <h1>Loại hình khám của {deptName}</h1>
            </div>
            <ServiceMgr deptId={deptId!} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
