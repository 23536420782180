import { Col, Empty, Popconfirm, Row, Spin } from 'antd';
import type moment from 'moment';
import { type FC } from 'react';
import { BsCloudSun, BsFillTrashFill, BsSun } from 'react-icons/bs';
import { type Doctor } from '../../../model/doctor';
import './_AssignmentViewer.scss';
interface AssignmentViewerProps {
  isLoading: boolean;
  doctorList: Doctor[];
  deleteDoctor: (doctor: Doctor) => Promise<void>;
  setNewChange: Function;
  startDate: moment.Moment;
}

const vnDayOfWeek = ['Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu'];

export const AssignmentViewer: FC<AssignmentViewerProps> = ({
  isLoading,
  doctorList,
  deleteDoctor,
  setNewChange,
  startDate,
}: AssignmentViewerProps) => {
  /**
 * Gets the date string to help display in AssignmentViewerHeader
 *
 * @param weekday should be the index of the day (Mon is 0, Tue is 1,..)
 */
  const getWeekDateString = (weekday: number) => {
    return startDate.startOfWeek().add(weekday, 'days').format("DD/MM");
  }

  return (
    <div className="assignment-schedule-ctr">
      {isLoading ? (
        <Spin />
      ) : (
        <div className="assignment-schedule-view">
          <AssignmentViewerHeader getWeekDateString={getWeekDateString} />
          <AssignmentViewerContent
            doctorList={doctorList}
            deleteDoctor={deleteDoctor}
            setNewChange={setNewChange}
          />
        </div>
      )}
    </div>
  );
};

interface AssignmentViewerHeaderProps {
  getWeekDateString: Function,
}

const AssignmentViewerHeader: FC<AssignmentViewerHeaderProps> = (
  {
    getWeekDateString,
  }
) => (
  <Row className="assignment-header">
    <Col className="assignment-col assignment-header-col
    assignment-doctor-row " span={4}>
      <h5>Bác sĩ</h5>
    </Col>
    <Col className="assignment-col assignment-header-col" span={2}>
      <h5>Buổi</h5>
    </Col>
    {vnDayOfWeek.map((day, i) => {
      return (
        <Col
          className="assignment-col assignment-header-col"
          span={3}
          key={`day-weekday-${i}`}>
          <h5>{day}</h5>
          <p>{getWeekDateString(i)}</p>
        </Col>
      );
    })}
    <Col className="assignment-col assignment-header-col" span={3}>
      <h5>Thao tác</h5>
    </Col>
  </Row>
);

interface AssignmentViewerContentProps {
  doctorList: Doctor[];
  deleteDoctor: (doctor: Doctor) => Promise<void>;
  setNewChange: Function;
}

export const AssignmentViewerContent: FC<AssignmentViewerContentProps> = ({
  doctorList,
  deleteDoctor,
  setNewChange,
}: AssignmentViewerContentProps) => (
  <Col className="assignment-content">
    {doctorList.length === 0 ? (
      <div className="assignment-content-empty-notice">
        <Empty
          description={" Không có bác sĩ, Hãy thêm bác sĩ " +
            "bằng cách nhấn nút \"Quản lý lịch\" ở trên."} />
      </div>
    ) : (
      doctorList.map((doctor, doctorI) => {
        return (
          <Row key={`doctor-${doctorI}`} className="assignment-doctor-tile">
            <Col
              className="assignment-doctor-label"
              span={4}
              key={`doctor-col-${doctorI}`}>
              <Row className="assignment-doctor-row">
                <Col span={16} className="assignment-doctor-func centered">
                  <div key={`doctor-name-${doctorI}`}>
                    <p>{doctor.name}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={2}
              className="assignment-day assignment-col"
              key={`day-period-${doctorI}`}>
              <Row
                className="assignment-morning assignment-sub-row"
                key={`day-am-${doctorI}`}>
                <BsSun size={20} />
              </Row>
              <Row
                className="assignment-afternoon assignment-sub-row"
                key={`day-pm-${doctorI}`}>
                <BsCloudSun size={20} />
              </Row>
            </Col>
            {[...Array(5)].map((_, dayI) => {
              return (
                <Col span={3} key={`doctor-day-${doctorI}-${dayI}`}
                  className="assignment-col">
                  <Row
                    key={`doctor-am-${doctorI}-${dayI}`}
                    className="assignment-sub-row day-checker"
                  >
                    <label
                      className="assignment-col-label"
                      htmlFor={`check-${doctorI}-${dayI}-am`}
                    >
                      <input type="checkbox"
                        id={`check-${doctorI}-${dayI}-am`}
                        defaultChecked={doctor.amAvail[dayI]}
                        onChange={(e) => {
                          doctor.amAvail[dayI] = e.target.checked;
                          setNewChange(true);
                        }}
                      />
                      <span className="custom-checkmark
                      custom-checkmark-morning">
                        <p>7.00 - 12.00</p>
                      </span>
                    </label>
                  </Row>
                  <Row
                    key={`doctor-pm-${doctorI}-${dayI}`}
                    className="assignment-sub-row day-checker"
                  >
                    <label
                      className="assignment-col-label"
                      htmlFor={`check-${doctorI}-${dayI}-pm`}>
                      <input type="checkbox"
                        id={`check-${doctorI}-${dayI}-pm`}
                        defaultChecked={doctor.pmAvail[dayI]}
                        onChange={(e) => {
                          doctor.pmAvail[dayI] = e.target.checked;
                          setNewChange(true);
                        }}
                      />
                      <span className="custom-checkmark">
                        <p>13.00 - 16.30</p>
                      </span>
                    </label>
                  </Row>
                </Col>
              );
            })}
            <Col span={3} className="assignment-col">
              <Popconfirm
                title={`Xóa bác sĩ ${doctor.name}?\n
                   (Lưu ý: đây là xóa vĩnh viễn!)`}
                className="assignment-col-delete-btn"
                onConfirm={async () => {
                  await deleteDoctor(doctor);
                }}>
                <BsFillTrashFill size={15} />
              </Popconfirm>
            </Col>
          </Row>
        );
      })
    )}
  </Col>
);
