import { type NavigateFunction } from 'react-router-dom';

enum DeptSettings {
  Appointment = '',
  WeeklySchedule = 'weekly-schedule',
  ServiceMgr = 'service-mgr',
}

const navToDept = (
  navigate: NavigateFunction,
  deptId: string,
  settings: DeptSettings
) => {
  navigate({ pathname: `/dept/${deptId}/${settings}` });
};

export { DeptSettings, navToDept };
