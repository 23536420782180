import { Button, Form, Input, Modal, notification } from 'antd';
import { useState, type FC } from 'react';
import { createDoctorInFirebase } from '../../../model/doctor';
import { defaultNotificationConfig } from '../../../service/notification';

interface AddDoctorModalProps {
  deptId: string;
  schedId: string;
  checkClickable: () => boolean;
  loadDoctorList: (schedId: string) => Promise<void>;
}

export const AddDoctorModal: FC<AddDoctorModalProps> = ({
  deptId,
  schedId,
  checkClickable,
  loadDoctorList,
}: AddDoctorModalProps) => {
  // the hospital of the user, should use this later for authentication TODO
  // Open and close for add doctor modal
  const [isAddDoctorModalVisible, setAddDoctorModalVisible] = useState(false);

  // Add doctor form
  const [form] = Form.useForm();

  /**
   * Add doctor modal helper, won't merge to addDoctor since not really
   * necessary
   */
  const handleAddDoctorSubmit = async () => {
    const values = await form.validateFields();
    await addDoctor(values.doctorName);
  };

  /**
   * Used to add doctor to database.
   * This is adding not to individual weekly-schedule but rather to a big
   * database of doctor, and then referenced by their id.
   *
   * @param doctorName the name of the new doctor
   */
  const addDoctor = async (doctorName: string) => {
    await createDoctorInFirebase(doctorName, deptId, schedId)
      .then(() => {
        notification.success(
          defaultNotificationConfig('Thêm bác sĩ thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Thêm bác sĩ không thành công')
        );
      });
    void loadDoctorList(schedId);
    toggleModal();
  };

  // This is for turning on and off add doctor modal, put here for convenience
  const toggleModal = () => {
    setAddDoctorModalVisible((prevStatus) => !prevStatus);
  };

  // Open and close for add doctor modal
  return (
    <>
      <Button
        className="scheduler-add-doctor-btn scheduler-viewer"
        onClick={() => {
          if (!checkClickable()) return;
          toggleModal();
        }}
      >
        Thêm bác sĩ
      </Button>
      <Modal
        title="Thêm bác sĩ"
        open={isAddDoctorModalVisible}
        onCancel={toggleModal}
        cancelText="Hủy"
        onOk={handleAddDoctorSubmit}
        // okText="Thêm"
        destroyOnClose
      >
        <Form form={form} preserve={false}>
          <Form.Item
            name="doctorName"
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập tên bác sĩ!',
              },
            ]}
            required
          >
            <Input maxLength={100} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
