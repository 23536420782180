import { type FC } from 'react';
import logo from '../../assets/logo_dark.png';
import LoginForm from '../../component/LoginForm';
import './Login.scss';
export const LoginPage: FC = () => {
  return (
    <main className="login-page">

      <section className="login-section">
        <LoginForm />
      </section>

      <div className="form__logo">
        <img src={logo} alt={''}></img>
        <h1>ConDoc</h1>
        <p>Hệ thống quản lý lịch hẹn</p>
      </div>
    </main>
  );
};
