import { notification } from 'antd';

/**
 * Notification config for notification services, should put this in some
 * global file actually, but lazy now lol
 *
 * TODO: put this in some place else
 *
 * @param text the text of the notification
 * @returns the notification config
 */
export const defaultNotificationConfig = (text: string) => {
  return notification.config({
    placement: 'topRight',
    duration: 2,
    bottom: 50,
    description: text,
  });
};
