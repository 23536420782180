import { Modal } from 'antd';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import { FaListUl, FaSignOutAlt, FaUserMd } from 'react-icons/fa';
import {
  MdSpaceDashboard
} from 'react-icons/md';
import {
  Menu,
  MenuItem,
  Sidebar,
  // SidebarHeader,
  SubMenu
} from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import condocLogo from '../../assets/logo_light.png';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logoutUser } from '../../redux/hospitalSlice';
import { DeptSettings, navToDept } from '../../service/navigator';
import { auth } from '../../utils/firebase';
import './_CDSidebar.scss';
export const CDSidebar = () => {
  const hospital = useAppSelector((state) => state.hospital);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showLogOut, setShowLogOut] = useState(false);
  const styles = {
    sideBarHeight: {
      height: '100vh',
    },
    menuIcon: {
      float: 'right',
      margin: '10px',
    },
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutUser());
        navigate('/');
      })
      .catch((err) => {
        alert('Đã có lỗi xảy ra khi đăng xuất!');
        console.error(err);
      });
  };

  return (
    <Sidebar style={styles.sideBarHeight}>
      <div className="sidebar_logo">
        <img src={condocLogo} alt="" />
        <h2>ConDoc</h2>
      </div>
      <Menu>
        <MenuItem
          onClick={() => {
            navigate('/dashboard');
          }}
          active={window.location.pathname === "/dashboard"}
          icon={<MdSpaceDashboard />}
        >
          Tổng quan
        </MenuItem>
        <SubMenu title="Stages" icon={<FaListUl />} label="Khoa">
          {hospital.departments.map((dept, i) => {
            return (
              <SubMenu key={`dept-${i}`} label={dept.name} title="Stages">
                <MenuItem
                  key={`dept-appt-list-${i}`}
                  icon={<BsFillCalendarEventFill />}
                  onClick={() => {
                    navToDept(navigate, dept.id, DeptSettings.Appointment);
                  }}
                >
                  Lịch hẹn
                </MenuItem>
                <MenuItem
                  key={`dept-weekly-schedule-${i}`}
                  icon={<FaUserMd />}
                  onClick={() => {
                    navToDept(navigate, dept.id, DeptSettings.WeeklySchedule);
                  }}
                >
                  Lịch bác sĩ
                </MenuItem>
              </SubMenu>
            );
          })}
        </SubMenu>
        <MenuItem
          onClick={() => { setShowLogOut(true) }}
          icon={<FaSignOutAlt />}>
          Đăng xuất
          <Modal
            open={showLogOut}
            onOk={() => { logout() }}
            onCancel={(e) => {
              e.stopPropagation();
              setShowLogOut(false)
            }}
            title="Bạn thực sự muốn đăng xuất?"
          >
          </Modal>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};
