import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  type DocumentData
} from "firebase/firestore";
import { DEPT_DB, SERVICES_DB } from "../../utils/database";
import { db } from "../../utils/firebase";

export interface Service {
  id?: string;
  name: string;
  price: number;
  isWithDoctor: boolean;
  isWithInsurance: boolean;
  schedId?: string;
}

/**
 * Processes service data from Firestore to an instance of {@link Service}.
 *
 * @param data data from Firebase
 * @param id id of the service
 * @returns an instance of {@link Service} processed from given data
 */
export const processServiceDataFromFireabse = (
  data: DocumentData,
  id: string,
): Service => {
  const service: Service = {
    id,
    name: data.name ?? 'không có',
    price: data.price ?? 0,
    isWithDoctor: data.isWithDoctor ?? false,
    isWithInsurance: data.isWithInsurance ?? false,
    schedId: data.schedId ?? null,
  }
  return service;
}

/**
 * Loads service list from Firebase of the specified department with
 * {@link deptId}.
 *
 * @param deptId the id of the department from which we want to load
 * @returns the list of {@link Service} that is in the specified department
 */
export const loadServicesFromFirebase = async (
  deptId: string
): Promise<Service[]> => {
  const serviceList: Service[] = [];
  await getDocs(servicesCol(deptId)).then((serviceDocs) => {
    serviceDocs.forEach((serviceDoc) => {
      const newService = processServiceDataFromFireabse(
        serviceDoc.data(),
        serviceDoc.id,
      );
      serviceList.push(newService);
    })
  });
  return serviceList;
}

/**
 * Adds a service with the given data in the specified department with the
 * {@link deptId}.
 *
 * @param deptId the id of the department which we want to add
 * @param serviceData the data of the new services. We won't touch the
 *  id of this {@link Service}
 * @returns the id of the new service
 */
export const createServiceInFirebase = async (
  deptId: string,
  serviceData: Service,
) => {
  const newService = await addDoc(servicesCol(deptId), {
    name: serviceData.name,
    price: serviceData.price,
    isWithDoctor: serviceData.isWithDoctor,
    isWithInsurance: serviceData.isWithInsurance,
    schedId: serviceData.schedId ?? null,
  })
  return newService.id;
}
/**
 * Updates a service with the given data in the specified department with the
 * {@link deptId}.
 *
 * @param deptId the id of the department which we want to add
 * @param serviceData the data of the new services
 */
export const updateServiceInFirebase = async (
  deptId: string,
  serviceData: Service,
) => {
  const serviceRef = doc(servicesCol(deptId), serviceData.id);
  await updateDoc(serviceRef, {
    name: serviceData.name,
    price: serviceData.price,
    isWithDoctor: serviceData.isWithDoctor,
    isWithInsurance: serviceData.isWithInsurance,
    schedId: serviceData.schedId ?? null,
  });
}

/**
 * Deletes the service with the given {@link serviceId} in the specified
 * department with {@link deptId}.
 *
 * @param deptId the id of the department which we want to add
 * @param serviceId the id of the service which we want to delete
 */
export const deleteServiceFromFirebase = async (
  deptId: string,
  serviceId: string,
) => {
  const serviceRef = doc(servicesCol(deptId), serviceId);
  await deleteDoc(serviceRef);
}

/**
 * Returns the collection reference of the services list of the given
 * department with id {@link deptId}.
 *
 * @param deptId the department from whose services we want to to get
 * @returns the collection reference of the services of the given department
 *  with id {@link deptId}.
 */
export const servicesCol = (deptId: string) =>
  collection(db, DEPT_DB, deptId, SERVICES_DB);
