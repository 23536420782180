import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

/**
 * Retrieve name of department based on its id
 *
 * @param deptId the department id
 * @returns the name of the department that the id is pointing to,
 *  return "[dept 404]" if none found, as silly it may seem
 */
const getDeptNameById = async (deptId: string): Promise<string> => {
  const deptRef = doc(db, 'departments', deptId);
  const deptSnapshot = await getDoc(deptRef);

  if (deptSnapshot.exists()) {
    return deptSnapshot.data().name;
  } else {
    return '[dept 404]';
  }
};

export { getDeptNameById };
