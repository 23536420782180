import { ApptStatus } from "../model/appointment";

/**
 * Helper method for getting [ApptStatus] from the string from Firebase.
 *
 * @param statusString the status string stored in Firebase
 * @returns The corresponding [ApptStatus]
 */
export const getStatusByString = (statusString: string): ApptStatus => {
  switch (statusString) {
    case "completed": return ApptStatus.completed;
    case "cancelled": return ApptStatus.cancelled;
    case "pending": return ApptStatus.pending;
    case "stale": return ApptStatus.stale;
    default: return ApptStatus.pending;
  }
}

/**
 * Convert given amount of money to VND currency
 *
 * @param amount given amount
 * @returns formatted string in VND currency
 */
export const toVNDString = (amount: number): String => {
  if (amount < 0) {
    return "[INVALID AMOUNT]";
  }
  // de-DE since there's no vi-VN, and idk better way for this lol
  return amount.toLocaleString('de-DE', { style: 'currency', currency: 'VND' });
}

/**
 * Gets difference in minutes between two [Date] object.
 *
 * @param before Date object before the after one
 * @param after Date object after the before one
 * @returns difference in minutes
 */
export const getDiffMinutes = (
  before: moment.Moment,
  after: moment.Moment,
): number => {
  const diffMs = after.valueOf() - before.valueOf();
  return Math.round(diffMs / 60000);
}
