import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  type DocumentData
} from "firebase/firestore";
import { DEPT_DB, DOCTOR_DB } from "../../utils/database";
import { getCurrentMilliseconds } from "../../utils/datetime_utils";
import { db } from "../../utils/firebase";

export type DoctorKey = Record<string, Doctor>;

export interface Doctor {
  id?: string;
  name: string;
  amAvail: boolean[]; // 0-4 for Mo-Fr
  pmAvail: boolean[]; // 0-4 for Mo-Fr
}

/**
 * Processes doctor data from Firestore to an instance of {@link Doctor}.
 *
 * @param data data from Firebase
 * @param id id of the doctor
 * @returns an instance of {@link Doctor} processed from given data
 */
export const processDoctorDataFromFirebase = (
  data: DocumentData,
  id: string,
): Doctor => {
  const amAvail: boolean[] = [];
  const pmAvail: boolean[] = [];
  for (let i = 0; i < 5; i++) {
    amAvail.push(false);
    pmAvail.push(false);
  }
  const doctor: Doctor = {
    id,
    name: data.name,
    amAvail,
    pmAvail,
  };
  return doctor;
}

/**
 * Retrieves doctor name by the given {@link doctorId} in the department
 * specified by {@link deptId}.
 *
 * @param deptId the department whose doctor we want to load from
 * @param doctorId the id of the doctor whose name we want to load
 * @returns the name of the doctor, if exists in the department
 */
export const getDoctorNameById = async (deptId: string, doctorId: string) => {
  const docRef = doc(doctorCol(deptId), doctorId);
  const doctorDoc = await getDoc(docRef);
  return (doctorDoc.exists()) ? doctorDoc.data()!.name : null;
}

/**
 * Loads doctor data from Firebase with the specified department with
 * {@link deptId} in the specified schedule with {@link schedId}.
 *
 * @param deptId the id of the department from which we want to load
 * @param schedId the schedule that the doctor is in
 * @returns the list of {@link Doctor} that is in the specified params
 */
export const loadDoctorsFromFirebase = async (
  deptId: string,
  schedId: string,
): Promise<Doctor[]> => {
  const doctorQuery = query(
    doctorCol(deptId),
    where('schedId', '==', schedId),
    orderBy('addedTime')
  );
  const doctorList: Doctor[] = [];
  const doctorDocs = await getDocs(doctorQuery);
  doctorDocs.forEach((doctorDoc) => {
    const doctor = processDoctorDataFromFirebase(
      doctorDoc.data(),
      doctorDoc.id
    );
    doctorList.push(doctor);
  });
  return doctorList;
}

/**
 * Adds a doctor with the given name in the specified department with the
 * {@link deptId}.
 *
 * @param doctorName name of the new doctor
 * @param deptId the id of the department which we want to add
 * @returns the id of the new doctor
 */
export const createDoctorInFirebase = async (
  doctorName: string,
  deptId: string,
  schedId: string,
): Promise<string> => {
  const newDoc = await addDoc(doctorCol(deptId), {
    name: doctorName,
    deptId,
    schedId,
    isDeleted: false,
    addedTime: getCurrentMilliseconds(),
  })
  return newDoc.id
}

/**
 * Deletes the doctor with the given {@link doctorId} in the specified
 * department with {@link deptId}.
 *
 * @param deptId the id of the department which we want to add
 * @param doctorId the id of the doctor whom we want to delete
 */
export const deleteDoctorFromFirebase = async (
  deptId: string,
  doctorId: string,
) => {
  const docRef = doc(doctorCol(deptId), doctorId);
  await deleteDoc(docRef);
};

/**
 * Returns the collection reference of the doctor of the given department with
 * id {@link deptId}.
 *
 * @param deptId the department from whose doctors we want to to get
 * @returns the collection reference of the doctors of the given department
 *  with id {@link deptId}.
 */
export const doctorCol = (deptId: string) =>
  collection(db, DEPT_DB, deptId, DOCTOR_DB);
