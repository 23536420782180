import { type DocumentData } from "@firebase/firestore";
import moment from "moment";
import { getAgeFromDobString } from "../../utils/datetime_utils";
import { getStatusByString } from "../../utils/utils";
import { getPaymentStatus, type PaymentStatus } from "../payment";
import { type Service } from "../service";

export enum ApptStatus {
  pending = "pending",
  completed = "completed",
  cancelled = "cancelled",
  stale = "stale",
}
export interface AppointmentInfo {
  id: string;
  name: string;
  phone: string;
  age: number;
  dateOfBirth: string;
  addr: string;
  email?: string;
  cmnd?: string;
  bhyt?: string;
  apptTime: moment.Moment;
  profileId: string;
  hospitalId: string;
  deptId: string;
  doctorId?: string;
  createDate: number;
  reason: string;
  status: ApptStatus,
  cancelReason?: String,
  statusUpdatedTime: moment.Moment;
  service?: Service,
  paymentStatus?: PaymentStatus,
  sttBhyt?: number, // số thứ tự BHYT
}

export interface ApptDay {
  id: string; // timestamp-based id
  date: number;
  // Firebase collection of ApptTimeslot
}

export interface ApptTimeslot {
  id: string; // timestamp-based id
  apptId: string; // points to the Appointment that reserves this timeslot
  isCompleted: boolean;
  time: number;
}

/**
 * Processes appt data from Firestore to an instance of {@link AppointmentInfo}
 *
 * @param data data from Firebase
 * @param id id of the appointment
 * @returns an instance of {@link AppointmentInfo} processed from given data
 */
export const processApptDataFromFirebase = (
  data: DocumentData,
  id: string,
): AppointmentInfo => {
  const pkg: AppointmentInfo = {
    id,
    name: data.patientName ?? '[không có]',
    phone: data.phone ?? '[không có]',
    age: getAgeFromDobString(data.dateOfBirth),
    dateOfBirth: data.dateOfBirth ?? '[không có]',
    addr: data.address ?? '[không có]',
    email: data.email ?? null,
    cmnd: data.cmnd ?? null,
    bhyt: data.insuranceNo ?? null,
    apptTime: moment(data.apptTime),
    profileId: data.profileId,
    hospitalId: data.hospitalId,
    deptId: data.deptId,
    doctorId: data.doctorId ?? null,
    createDate: data.createDate,
    reason: data.reason ?? '[không có]',
    status: getStatusByString(data.status ?? 'pending'),
    cancelReason: data.cancelReason,
    statusUpdatedTime:
      data.statusUpdatedTime != null
        ? moment(data.statusUpdatedTime)
        : moment(),
    paymentStatus: getPaymentStatus(data.paymentId),
    service: data.service ?? null,
    sttBhyt: data.sttBhyt ?? null,
  };
  return pkg;
}
