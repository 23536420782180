import { Form, Input, Modal } from 'antd';
import { type FC } from 'react';
import './_AddDoctorModal.scss';

interface CancelModalProps {
  cancelAppointment: (cancelReason: string) => Promise<void>;
  isVisible: boolean;
  toggleModal: () => void;
  cancelReason?: String;
}

export const CancelModal: FC<CancelModalProps> = ({
  cancelAppointment,
  isVisible,
  toggleModal,
  cancelReason,
}: CancelModalProps) => {
  // Add doctor form
  const [form] = Form.useForm();

  const isFixing = cancelReason != null;

  const title = isFixing ? 'Sửa lý do hủy cuộc hẹn' : 'Ghi lý do hủy cuộc hẹn';

  const okText = isFixing ? 'Sửa' : 'Hủy khám';

  /**
   * Add doctor modal helper, won't merge to addDoctor since not really
   * necessary
   */
  const handleCancel = async () => {
    const values = await form.validateFields();
    await cancelAppointment(values.cancelReason);
    toggleModal();
  };

  // Open and close for add doctor modal
  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={toggleModal}
      cancelText="Thôi"
      onOk={handleCancel}
      okText={okText}
      destroyOnClose
    >
      <Form form={form} preserve={false}>
        <Form.Item
          name="cancelReason"
          rules={[
            {
              required: true,
              message: 'Bạn chưa nhập lý do hủy!',
            },
          ]}
          initialValue={cancelReason ?? ''}
          required
        >
          <Input maxLength={100} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
