export const enum PaymentStatus {
  unknown = 'không rõ',
  payInHospital = 'Trả tại bệnh viện',
  paidThroughCard = 'Đã trả qua thẻ',
}

/**
 * Gets the current payment status of through paymentId.
 * Special values: null | undefined | 'inHospital': need to pay in hopsital,
 * will return {@link PaymentStatus.payInHospital}.
 *
 * Otherwise, assumed to have been paid through card. In such case, will return
 * {@link PaymentStatus.paidThroughCard}
 *
 * @param paymentId the payment id
 * @returns corresponding {@link PaymentStatus}
 */
export const getPaymentStatus = (
  paymentId: string | null | undefined
): PaymentStatus => {
  switch (paymentId) {
    case null:
    case undefined:
      return PaymentStatus.unknown;
    case 'inHospital':
      return PaymentStatus.payInHospital;
    default:
      return PaymentStatus.paidThroughCard;
  }
}
