import { Col, Row } from 'antd';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { type FC } from 'react';
import { useNavigate } from 'react-router';
import { LoginKey, useLoginForm } from '../../model/login';
import { useAppDispatch } from '../../redux/hooks';
import { loginUser } from '../../redux/hospitalSlice';
import { auth, db } from '../../utils/firebase';
import './_LoginForm.scss';

export const LoginForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useLoginForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data: { email: string; password: string }) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((hospitalCred) => {
        const hospital = hospitalCred.user;
        const hospitalId = hospital.uid;
        getDoc(doc(db, 'hospitals', hospitalId))
          .then((hospitalData) => {
            const data = hospitalData.data();
            dispatch(
              loginUser({
                name: data?.name,
                id: hospitalId,
                departments: [],
                addr: data?.addr,
                phone: data?.phone,
                logo: data?.logo,
                email: data?.username,
                viewPaidPassword: data?.viewPaidPassword,
              })
            );
            navigate('/dashboard');
          })
          .catch(() => {
            alert('Không có dữ liệu bệnh viện!');
          });
      })
      .catch(() => {
        alert('Email hay mật khẩu bị lỗi, xin hãy thử lại!');
      });
  });

  return (
    <>
      <form className="login-form form" onSubmit={onSubmit}>

        <h2 className="login-title">Đăng nhập</h2>
        <div className="form__control">
          <label>
            <h5 className="form__prompt">Email</h5>
            <input
              type="text"
              placeholder="bacsi@benhvien.com"
              {...register(LoginKey.email)}
            />
          </label>
          {errors.email != null && (
            <p className="form__error">{errors.email.message}</p>
          )}
        </div>

        <div className="form__control">
          <label>
            <h5 className="form__prompt">Mật khẩu</h5>
            <input
              type="password"
              placeholder="123456"
              {...register(LoginKey.password)}
            />
          </label>
          {errors.password != null && (
            <p className="form__error">{errors.password.message}</p>
          )}
        </div>

        <div className="form__control form__buttons">
          <Col>
            <Row className="form__button_row">
              <button
                className="form__button form__signin_button primary-button"
                type="submit">
                Đăng nhập
              </button>
            </Row>

            <Row className="form__button_row">
              Chưa có tài khoản?&nbsp;
              <a
                onClick={() => {
                  navigate('/signin');
                }}
                className="form__button form__signup_button"
              >
                Đăng kí
              </a>
            </Row>
          </Col>
        </div>
      </form>
    </>
  );
};
