import {
  addDoc,
  collection,
  doc,
  updateDoc,
  type DocumentData
} from "firebase/firestore";
import moment from "moment";
import { DEPT_DB, SCHEDULE_DB, WEEKLY_ASM_DB } from "../../utils/database";
import { getCurrentMilliseconds } from "../../utils/datetime_utils";
import { db } from "../../utils/firebase";

export type Assignment = Record<string, boolean>;

export interface WeeklyAssignment {
  id: string | null;
  weekStart: moment.Moment;
  amAssignments: Assignment[];
  pmAssignments: Assignment[];
  updatedTime?: moment.Moment;
}

/**
 * Processes weekly assignment data from Firestore to an instance of
 * {@link WeeklyAssignment}
 *
 * @param data data from Firebase
 * @param id id of the weekly assignment
 * @returns an instance of {@link WeeklyAssignment} processed from given data
 */
export const processAssignmentDataFromFirebase = (
  data: DocumentData,
  id: string,
): WeeklyAssignment => {
  const assignment = {
    id,
    weekStart: moment(data.weekStart),
    amAssignments: data.amAssignments ?? [],
    pmAssignments: data.pmAssignments ?? [],
    updatedTime: data.updatedTime != null
      ? moment(data.updatedTime)
      : undefined,
  };
  return assignment;
}

/**
 * Creates a new instance of weekly assignment on the weekly-assignment
 * collection. The start time will be defined by whatever the user wants it to
 * be, but preferrably it should be the start time of the week that the
 * assignment is.
 *
 * The assignment will be loaded with the given {@link amAssignments} and
 * {@link pmAssignments}, if any.
 *
 * @param deptId the department id to which the schedule belongs
 * @param schedId the schedule id to which this assignment belongs
 * @param weekStartTime the milliseconds in which this assignment belongs to
 * @param amAssignments the morning assigments of doctor
 * @param pmAssignments the afternoon assignments of doctor
 * @returns the ID of the weekly assignment on the database
 */
export const createAssignmentOnFirebase = async (
  deptId: string,
  schedId: string,
  weekStartTime: number,
  amAssignments?: Assignment[],
  pmAssignments?: Assignment[],
): Promise<string> => {
  // if not, create new assignment on firestore
  const newSched = await addDoc(assignmentCol(deptId, schedId), {
    weekStart: weekStartTime,
    amAssignments,
    pmAssignments,
    updatedTime: getCurrentMilliseconds(),
  });
  return newSched.id;
}

/**
 * Updates the assignment with the given {@link assignmentId} with the new AM
 * and PM assignments.
 *
 * @param deptId the department id to which the schedule belongs
 * @param schedId the schedule id to which this assignment belongs
 * @param assignmentId the id of the assignment that we want to update
 * @param amAssignments the morning assigments of doctor
 * @param pmAssignments the afternoon assignments of doctor
 */
export const updateAssignmentOnFirebase = async (
  deptId: string,
  schedId: string,
  assignmentId: string,
  amAssignments: Assignment[],
  pmAssignments: Assignment[],
) => {
  const assignmentDoc = doc(assignmentCol(deptId, schedId), assignmentId);
  await updateDoc(assignmentDoc, {
    amAssignments,
    pmAssignments,
    updatedTime: getCurrentMilliseconds(),
  });
}

/**
 * Returns the collection reference of the weekly assignment of the given
 * department with id {@link deptId}.
 *
 * @param deptId the department from whose weekly assignment we want to to get
 * @returns the collection reference of the weekly assignment of the given
 *  department with id {@link deptId}.
 */
export const assignmentCol = (deptId: string, schedId: string) =>
  collection(db, DEPT_DB, deptId, SCHEDULE_DB, schedId, WEEKLY_ASM_DB);
