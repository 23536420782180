import { Col, Row } from 'antd';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';
import AppointmentView from '../../component/AppointmentView';
import CDSidebar from '../../component/CDSidebar';
import './_Dept.scss';

export const Dept: FC = () => {
  const { deptId } = useParams();

  return (
    <div className="dept-root dashboard-inner">
      <Row>
        <Col sm={6} lg={4} xl={3}>
          <CDSidebar />
        </Col>
        <Col sm={18} lg={20} xl={21}>
          {deptId == null ? (
            <h1>Hãy chọn một khoa để xem chi tiết!</h1>
          ) : (
            <AppointmentView deptId={deptId} />
          )}
        </Col>
      </Row>
    </div>
  );
};
