import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { type DeptProfile } from '../../utils/types';

export interface DeptSignup {
  name: string;
  phone: string;
}

export const DeptProfileKey: { [P in keyof DeptSignup]: P } = {
  name: 'name',
  phone: 'phone',
};

export const signupSchema: yup.SchemaOf<DeptSignup> = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
});

export const useSignupForm = () => {
  return useForm<DeptProfile>({ resolver: yupResolver(signupSchema) });
};
