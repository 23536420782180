import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface Signup {
  email: string;
  password: string;
  name: string;
  addr: string;
  phone: string;
  logo: string;
}

export const SignupKey: { [P in keyof Signup]: P } = {
  email: 'email',
  password: 'password',
  name: 'name',
  addr: 'addr',
  phone: 'phone',
  logo: 'logo',
};

export const signupSchema: yup.SchemaOf<Signup> = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  name: yup.string().required(),
  addr: yup.string().required(),
  phone: yup.string().required(),
  logo: yup.string().required(),
});

export const useSignupForm = () => {
  return useForm<Signup>({ resolver: yupResolver(signupSchema) });
};
