import { addDoc, collection } from 'firebase/firestore';
import { type FC } from 'react';
import {
  DeptProfileKey, useSignupForm as useDeptSignupForm, type DeptSignup
} from '../../model/deptSignup';
import { useAppSelector } from '../../redux/hooks';
import { db } from '../../utils/firebase';
import './_DeptMgr.scss';

interface DeptMgrProps {
  doneSubmit: Function,
}

export const DeptMgr: FC<DeptMgrProps> = ({
  doneSubmit,
}) => {
  const hospital = useAppSelector((state) => state.hospital);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useDeptSignupForm();

  const onSubmit = handleSubmit((data: DeptSignup) => {
    addDoc(collection(db, 'departments'), { ...data, hospitalId: hospital.id })
      .then(() => {
        alert(`Thêm khoa ${data.name} thành công!`);
        doneSubmit();
      })
      .catch(() => {
        alert('Đã xảy ra lỗi hệ thống, vui lòng thử lại.');
        doneSubmit();
      });
  });

  return (
    <form className="dept-signup-form form" onSubmit={onSubmit}>
      <div className="form__control">
        <label>
          <span className="form__prompt">Tên khoa</span>
          <input
            type="text"
            placeholder="Khoa điều dưỡng"
            {...register(DeptProfileKey.name)}
          />
        </label>
        {errors.name != null && (
          <p className="form__error">{errors.name.message}</p>
        )}
      </div>

      <div className="form__control">
        <label>
          <span className="form__prompt">Số điện thoại</span>
          <input placeholder="0912345678" {...register(DeptProfileKey.phone)} />
        </label>
        {errors.phone != null && (
          <p className="form__error">{errors.phone.message}</p>
        )}
      </div>

      <div className="form__control form__buttons">
        <button className="form__button form__signin_button primary-button"
          type="submit">
          Thêm khoa khám
        </button>
      </div>
    </form>
  );
};
