import moment from "moment";

/**
 * @returns the current milliseconds since epoch
 */
export const getCurrentMilliseconds = () => moment().valueOf();

/**
 * Retrieves the age from the given {@link dobString} up to this current moment.
 * Returns -1 if the input is not valid
 *
 * @param dobString the date of birth string
 * @returns the age up to the current moment, or -1 if {@link dobString} is
 *  invalid.
 */
export const getAgeFromDobString = (dobString: string) => {
  const dobDate = moment(dobString, 'DD/MM/YYYY');
  return (dobDate.isValid())
    ? moment().diff(dobDate, 'years')
    : -1;
}
