import { Col, Row } from 'antd';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { type FC } from 'react';
import { useNavigate } from 'react-router';
import { SignupKey, useSignupForm, type Signup } from '../../model/signup';
import { useAppDispatch } from '../../redux/hooks';
import { loginUser } from '../../redux/hospitalSlice';
import { auth, db } from '../../utils/firebase';
import './_SignupForm.scss';

export const SignupForm: FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useSignupForm();
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data: Signup) => {
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (hospitalCred) => {
        const hospitalId = hospitalCred.user.uid;
        setDoc(doc(db, 'hospitals', hospitalId), {
          email: data.email,
          name: data.name,
          addr: data.addr,
          phone: data.phone,
          logo: data.logo,
          apptCount: 0,
        })
          .then(() => {
            dispatch(
              loginUser({
                id: hospitalId,
                name: data.name,
                addr: data.addr,
                phone: data.phone,
                logo: data.logo,
                departments: [],
                email: data.email,
              })
            );
            alert('Đăng kí thành công, chào mừng đến với ConDoc!');
            navigate('/dashboard');
          })
          .catch((err) => {
            console.error(err);
            alert('Đăng kí lỗi, hãy thử lại!');
          });
      })
      .catch((err) => {
        console.error(err);
        alert('Đăng kí lỗi, hãy thử lại!');
      });
  });

  return (
    <form className="login-form form" onSubmit={onSubmit}>
      <h2 className="login-title">Đăng ký</h2>
      <div className="form__control">
        <label>
          <h5 className="form__prompt">Email</h5>
          <input
            type="text"
            placeholder="bacsi@benhvien.com"
            {...register(SignupKey.email)}
          />
        </label>
        {errors.email != null && (
          <p className="form__error">{errors.email.message}</p>
        )}
      </div>

      <div className="form__control">
        <label>
          <h5 className="form__prompt">Mật khẩu</h5>
          <input
            type="password"
            placeholder="123456"
            {...register(SignupKey.password)}
          />
        </label>
        {errors.password != null && (
          <p className="form__error">{errors.password.message}</p>
        )}
      </div>

      <div className="form__info">
        <h4 className="form__info_head">Thông tin bệnh viện</h4>

        <div className="form__control">
          <label>
            <h5 className="form__prompt">Tên bệnh viện</h5>
            <input
              type="text"
              placeholder="Bệnh viện Q.X"
              {...register(SignupKey.name)}
            />
          </label>
          {errors.name != null && (
            <p className="form__error">{errors.name.message}</p>
          )}
        </div>

        <div className="form__control">
          <label>
            <h5 className="form__prompt">Địa chỉ</h5>
            <input
              type="text"
              placeholder="1234 Đường Quận"
              {...register(SignupKey.addr)}
            />
          </label>
          {errors.addr != null && (
            <p className="form__error">{errors.addr.message}</p>
          )}
        </div>

        <div className="form__control">
          <label>
            <h5 className="form__prompt">Số điện thoại</h5>
            <input
              type="tel"
              placeholder="0912345678"
              {...register(SignupKey.phone)}
            />
          </label>
          {errors.phone != null && (
            <p className="form__error">{errors.phone.message}</p>
          )}
        </div>

        <div className="form__control">
          <label>
            <h5 className="form__prompt">Logo URL</h5>
            <input
              type="url"
              placeholder="https://example.com"
              {...register(SignupKey.logo)}
            />
          </label>
          {errors.logo != null && (
            <p className="form__error">{errors.logo.message}</p>
          )}
        </div>
      </div>

      <div className="form__control form__buttons">
        <Col>
          <Row className="form__button_row">
            <button className="form__button form__signin_button primary-button"
              type="submit">
              Đăng kí
            </button>
          </Row>

          <Row className="form__button_row">
            Đã có tài khoản?&nbsp;
            <a
              onClick={() => {
                navigate('/');
              }}
              className="form__button form__signup_button"
            >
              Đăng nhập
            </a>
          </Row>
        </Col>
      </div>
    </form>
  );
};
