import logo from '../../assets/logo_dark.png';
import SignupForm from '../../component/SignupForm';
import './Signup.scss';

export const Signup: React.FC = () => {
  return (
    <main className="login-page">
      <section className="login-section">
        <SignupForm />
      </section>
      <div className="form__logo">
        <img src={logo} alt={''}></img>
        <h1>ConDoc</h1>
        <p>Hệ thống quản lý lịch hẹn</p>
      </div>
    </main>
  );
};
