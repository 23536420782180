import { Breadcrumb, Button, Col, Modal, Row } from 'antd';
import { useState, type FC } from 'react';
import { BsFillCalendarEventFill, BsShieldFillPlus } from 'react-icons/bs';
import { MdMedicalServices } from 'react-icons/md';
import { useAppSelector } from '../../redux/hooks';
import DeptMgr from '../DeptMgr';
import SummaryTile from '../SummaryTile';
import TabBar from '../TabBar/index';
import DeptSummaryCard from './DeptSummaryCard';
import './_Summary.scss';

export const Summary: FC = () => {
  // show add dept
  const [showAddDept, setShowAddDept] = useState(false);

  const hospital = useAppSelector((state) => state.hospital);
  let apptCount = 0;
  hospital.departments.forEach((dept) => {
    apptCount += dept.apptCount;
  });

  return (
    <>
      <TabBar />
      <Row className="summary-root" gutter={20}>
        <Col className="summary-intro" span={14}>
          <div className="summary-breadcrumbs">
            <Breadcrumb separator=">" >
              <Breadcrumb.Item>
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Tổng quan
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row className="summary-tiles">
            <SummaryTile
              icon={<BsFillCalendarEventFill size={30} />}
              bigDisplay={apptCount}
              smallDisplay={"Lịch hẹn"}
              statDiff={20}
            />
            {
              // For logic devs here, get BHYT/Serv types and differences
              // vs previous day
            }
            <SummaryTile
              icon={<BsShieldFillPlus size={30} />}
              bigDisplay={73}
              smallDisplay={"BHYT"}
              statDiff={-35}
            />
            <SummaryTile
              icon={<MdMedicalServices size={30} />}
              bigDisplay={200}
              smallDisplay={"Dịch vụ"}
              statDiff={55}
            />
          </Row>
          <Row className="summary-dept-heading heading-div">
            <h4>
              Chi tiết các khoa
            </h4>
            <Button
              type="primary"
              onClick={() => { setShowAddDept(true) }}>
              Thêm khoa
            </Button>
            <Modal title="Thêm khoa khám" open={showAddDept}
              onOk={() => { setShowAddDept(false) }}
              onCancel={() => { setShowAddDept(false) }}
              footer={null}
            >
              <DeptMgr doneSubmit={() => { setShowAddDept(false) }} />
            </Modal>
          </Row>
          <div className="summary-dept">
            <Row className="summary-dept-label">
              <Col span={20}>
                <h5>Khoa</h5>
              </Col>
              <Col span={4} className="centered">
                <h5>Số cuộc hẹn</h5>
              </Col>
            </Row>
            <div className="dept-cards-wrapper">
              {hospital.departments.map((dept, i) => {
                return <DeptSummaryCard
                  deptInfo={dept} key={`dept-${dept.id}`} />;
              })}
            </div>
          </div>
        </Col>
        <Col span={10} className="summary-right-col">
        </Col>
      </Row>
    </>
  );
};
