import { Col, Row } from 'antd';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeptSettings, navToDept } from '../../../service/navigator';
import { type DeptProfile } from '../../../utils/types';
import './_DeptSummaryCard.scss';

interface DeptSummaryCardProps {
  deptInfo: DeptProfile;
}
export const DeptSummaryCard: FC<DeptSummaryCardProps> = ({
  deptInfo,
}: DeptSummaryCardProps) => {
  const navigate = useNavigate();

  return (
    <Row
      onClick={() => {
        navToDept(navigate, deptInfo.id, DeptSettings.Appointment);
      }}
      className={ "summary-card-root"}>
      <Col span={20}>
        <span>{deptInfo.name}</span>
      </Col>
      <Col span={4} className="centered">
        <span>{deptInfo.apptCount}</span>
      </Col>
    </Row >
  );
};
