import { Button, Form, Input, Modal, notification } from 'antd';
import { useState, type FC } from 'react';
import { createScheduleOnFirebase } from '../../../model/schedule';
import { defaultNotificationConfig } from '../../../service/notification';

interface AddScheduleModalProps {
  deptId: string;
  loadScheduleList: () => Promise<void>;
}

export const AddScheduleModal: FC<AddScheduleModalProps> = ({
  deptId,
  loadScheduleList,
}: AddScheduleModalProps) => {
  // the hospital of the user, should use this later for authentication TODO
  // Open and close for add schedule modal
  const [isModalVisible, setModalVisible] = useState(false);

  // Add schedule form
  const [form] = Form.useForm();

  /**
   * Add schedule modal helper, won't merge to addSchedule since not really
   * necessary
   */
  const handleAddScheduleSubmit = async () => {
    const values = await form.validateFields();
    await addSchedule(values.schedName);
  };

  /**
   * Handles adding new schedule to database.
   *
   * @param schedName the name of the new schedule
   */
  const addSchedule = async (schedName: string) => {
    await createScheduleOnFirebase(deptId, schedName)
      .then(() => {
        notification.success(
          defaultNotificationConfig('Thêm lịch bác sĩ mới thành công!')
        );
      })
      .catch(() => {
        notification.error(
          defaultNotificationConfig('Thêm lịch bác sĩ mới không thành công')
        );
      });
    void loadScheduleList();
    toggleModal();
  };
  // This is for turning on and off add schedule modal, put here for convenience
  const toggleModal = () => {
    setModalVisible((prevStatus) => !prevStatus);
  };
  // Open and close for add schedule modal
  return (
    <>
      <Button
        className="scheduler-add-schedule-btn scheduler-viewer"
        onClick={toggleModal}
      >
        Thêm nhóm lịch mới
      </Button>
      <Modal
        title="Thêm nhóm lịch bác sĩ"
        open={isModalVisible}
        onCancel={toggleModal}
        cancelText="Hủy"
        onOk={handleAddScheduleSubmit}
        // okText="Thêm"
        destroyOnClose
      >
        <Form form={form} preserve={false}>
          <Form.Item
            name="schedName"
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập tên lịch mới!',
              },
            ]}
            required
          >
            <Input maxLength={100} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
