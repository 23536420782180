import { type User } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import './App.scss';
import Dashboard from './pages/Dashboard';
import Dept from './pages/Dept';
import { LoginPage } from './pages/Login';
import Services from './pages/Services';
import { Signup } from './pages/Signup';
import WeeklySchedule from './pages/WeeklySchedule';
import { useAppDispatch } from './redux/hooks';
import { loadDept, loginUser, logoutUser } from './redux/hospitalSlice';
import { auth, db } from './utils/firebase';
import { type DeptProfile } from './utils/types';

// DO NOT REMOVE THIS IMPORT!!!
import * as modded_moment from './utils/vn_datetime';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  // set global timezone to Asia/Ho_Chi_Minh for standardization
  moment.tz.setDefault('Asia/Ho_Chi_Minh');

  // DO NOT REMOVE THIS LOG!!!
  console.log(`Current time is ${modded_moment.default().toLocaleString()}.`);

  const populateUserSlice = async (hospital: User) => {
    // get if existed or create if not existed
    const docId = doc(db, 'hospitals', hospital.uid);
    const userSnapshot = await getDoc(docId);
    if (userSnapshot.exists()) {
      // if user exist, grab user data and populate redux userSlice
      const userInfo = userSnapshot.data();
      dispatch(
        loginUser({
          id: hospital.uid,
          name: userInfo.name,
          departments: userInfo.departments,
          addr: userInfo.addr,
          phone: userInfo.phone,
          logo: userInfo.logo,
          email: userInfo.email,
          viewPaidPassword: userInfo.viewPaidPassword,
        })
      );
    }
    let deptData: DeptProfile[] = [];

    const q = query(
      collection(db, 'departments'),
      where('hospitalId', '==', hospital.uid)
    );
    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const dept: DeptProfile = {
            name: data.name,
            id: doc.id,
            phone: data.phone,
            hospitalId: data.hospitalId,
            apptCount: data.apptCount ?? 0,
          };
          deptData = [...deptData, dept];
        });
        dispatch(loadDept(deptData));
      })
      .catch((err) => {
        alert('Lỗi cập nhật thông tin các khoa');
        console.error(err);
      });
  };

  useEffect(() => {
    const authListener = auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        // user is signed in
        void populateUserSlice(user);
      } else {
        // No user is signed in, clear the userSlice store
        dispatch(logoutUser());
      }
    });
    return () => {
      authListener();
    }; // unsubscribe authentication listener
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* hospital */}
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/signin" element={<Signup />}></Route>

          {/* department */}
          <Route
            path="/dept/"
            element={<Navigate to={'/dashboard'}></Navigate>}
          ></Route>
          <Route path="/dept/:deptId" element={<Dept />}></Route>
          <Route
            path="/dept/:deptId/weekly-schedule"
            element={<WeeklySchedule />}
          ></Route>
          <Route
            path="/dept/:deptId/service-mgr"
            element={<Services />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
