import {
  Breadcrumb, Button, Calendar, Col, Collapse, Empty, Form,
  Input, Modal, notification, Row
} from 'antd';
import 'dayjs/locale/vi';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
  type Unsubscribe
} from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useRef, useState, type FC } from 'react';
import { BsFillCalendarEventFill, BsShieldFillPlus } from 'react-icons/bs';
import { MdMedicalServices } from 'react-icons/md';
import { RxReload } from 'react-icons/rx';
import {
  processApptDataFromFirebase,
  type AppointmentInfo
} from '../../model/appointment';
import { PaymentStatus } from '../../model/payment';
import { useAppSelector } from '../../redux/hooks';
import { defaultNotificationConfig } from '../../service/notification';
import { db } from '../../utils/firebase';
import AppointmentCard from '../AppointmentCard';
import ServiceMgr from '../ServiceMgr';
import SummaryTile from '../SummaryTile';
import TabBar from '../TabBar/index';
import './_AppointmentView.scss';
interface AppointmentViewProps {
  deptId: string;
}

export const AppointmentView: FC<AppointmentViewProps> = ({
  deptId,
}: AppointmentViewProps) => {
  const hospital = useAppSelector((state) => state.hospital);

  // appointment list that is currently loaded
  const [apptList, setApptList] = useState<AppointmentInfo[]>([]);

  // the date whose appointments the user wants to view
  const [selDate, setSelDate] = useState<moment.Moment>(moment());

  // the dept name of this particular department
  const [deptName, setDeptName] = useState('');

  // the loading indicator for appt list
  const [isLoading, setLoading] = useState(true);

  const [isVisibleConDiKeToan, setIsVisibleConDiKeToan] = useState(false);

  const [isConDiKeToan, setIsConDiKeToan] = useState(false);

  const [conDiKeToanForm] = Form.useForm();
  const { Panel } = Collapse;
  // unsusbcription for appt snapshots
  const unsubRef = useRef<Unsubscribe>();

  useEffect(() => {
    // retrieve department name
    const deptRef = doc(db, 'departments', deptId);
    void getDoc(deptRef).then((deptSnapshot) => {
      if (deptSnapshot.exists()) {
        setDeptName(deptSnapshot.data().name);
      }
    });

    void loadAppointmentsByDate(selDate);

    return () => {
      // unsub on dismount
      if (unsubRef.current != null) {
        unsubRef.current();
      }
    };
  }, [deptId]);

  /**
   * Retrieves the appointments of the given raw date. This loads by the year
   * month date of the given {@link raw}, not depending on timezones or time
   * stamp.
   * @param raw the raw date that you want to load appointments
   */
  const loadAppointmentsByDate = async (raw: moment.Moment) => {
    setLoading(true);
    // unsubscribing from previous snapshot
    if (unsubRef.current != null) {
      unsubRef.current();
    }

    // sanitizing input
    const date = moment([raw.year(), raw.month(), raw.date()]);

    // gets start and end of day
    const startDayTime = date.startOfDay().valueOf();
    const endDayTime = date.endOfDay().valueOf();

    // appointment query
    const apptQuery = query(
      collection(db, 'appointment'),
      where('deptId', '==', deptId),
      where('apptTime', '>=', startDayTime),
      where('apptTime', '<=', endDayTime),
      orderBy('apptTime')
    );

    // request from firebase
    const unsub = onSnapshot(
      apptQuery,
      (querySnapshot) => {
        let updatedList: AppointmentInfo[] = [];
        querySnapshot.forEach((doc) => {
          const appt = processApptDataFromFirebase(doc.data(), doc.id);
          updatedList = [...updatedList, appt];
        });
        setApptList(updatedList);
        setLoading(false);
      },
      (err) => {
        notification.error(defaultNotificationConfig('Lỗi khi tải lịch hẹn!'));
        setLoading(false);
        console.error(err);
      }
    );

    // register current unsubscription, to be unsubbed later when change date
    unsubRef.current = unsub;
  };

  // The appt list view separated for clean code
  const apptListView =
    apptList.length === 0 ? (
      <div className="appt-view-list no-appt-notice">
        <Empty description={"Chưa có lịch hẹn nào vào hôm nay"} />
      </div>
    ) : (
      <div className="appt-view-list">
        <Collapse accordion >
          {apptList.map((appt, i) => {
            return (
              (!isConDiKeToan ||
                appt.paymentStatus === PaymentStatus.paidThroughCard) && (
                <Panel key={`appt-${i}`}
                  className={i % 2 === 0 ? "appt-view-dept"
                    : "appt-view-dept any-other-div"}
                  header={<AppointmentCard
                    showPaidAmount={isConDiKeToan}
                    deptId={deptId}
                    appt={appt}
                    index={i}
                  />
                  }>
                  <AppointmentCard
                    showPaidAmount={isConDiKeToan}
                    deptId={deptId}
                    appt={appt}
                    index={i}
                    showExtraDetails={true}
                  />
                </Panel>
              )
            );
          })}
        </Collapse>
      </div>
    );

  const viewPaidOnlyButton = (
    <>
      <Button
        className="appt-view-function-button dull-button"
        onClick={() => {
          setIsVisibleConDiKeToan(true);
        }}
      >
        Xem danh sách đã thanh toán
      </Button>
      <Modal
        title={'Để xem số tiền đã thanh toán, bạn phải nhập mật khẩu'}
        open={isVisibleConDiKeToan}
        cancelText="Thôi"
        onOk={async () => {
          const values = await conDiKeToanForm.validateFields();
          if (hospital.viewPaidPassword! !== values.viewPaidPassword) {
            notification.error(defaultNotificationConfig('Mật khẩu bị sai!'));
          } else {
            setIsConDiKeToan(true);
          }
          setIsVisibleConDiKeToan(false);
        }}
        onCancel={() => {
          setIsVisibleConDiKeToan(false);
        }}
        destroyOnClose
      >
        <Form form={conDiKeToanForm} preserve={false}>
          <Form.Item
            name="viewPaidPassword"
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập mật khẩu!',
              },
            ]}
            required
          >
            <Input maxLength={100} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  return (
    <Col className="appt-view-root">
      <TabBar />
      <Row className="appt-view-details" gutter={[20, 20]}>
        <Col className="appt-view-intro" span={16}>
          <div className="appt-view-breadcrumbs">
            <Breadcrumb separator=">" >
              <Breadcrumb.Item>
                Khoa
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {deptName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row className="summary-tiles">
            <SummaryTile
              icon={<BsFillCalendarEventFill />}
              bigDisplay={apptList.length}
              smallDisplay={"Lịch hẹn"}
              statDiff={20}
            />
            {
              // For logic devs here, get BHYT/Serv types and differences
              // vs previous day
            }
            <SummaryTile
              icon={<BsShieldFillPlus />}
              bigDisplay={73}
              smallDisplay={"BHYT"}
              statDiff={-35}
            />
            <SummaryTile
              icon={<MdMedicalServices />}
              bigDisplay={200}
              smallDisplay={"Dịch vụ"}
              statDiff={55}
            />
          </Row>

          <Row className="appt-view-function-display heading-div">
            <h4>{isConDiKeToan ? "Cuộc hẹn đã thanh toán trước"
              : "Lịch hẹn"} ngày {selDate.toDateString()}</h4>
            {/* reload button */}
            <div className="appt-view-function-button-wrapper">
              <Button
                className="appt-view-function-button dull-button"
                onClick={() => {
                  void loadAppointmentsByDate(selDate);
                }}
                icon={<RxReload />}
              >
                Tải lại
              </Button>
              {isConDiKeToan ? (
                <Button
                  className="appt-view-function-button dull-button"
                  onClick={() => {
                    setIsConDiKeToan(false);
                  }}
                >
                  Xem tất cả lịch hẹn
                </Button>
              ) : (
                viewPaidOnlyButton
              )}
            </div>
          </Row>
          <div className="appt-view-wrapper">
            <Row className="appt-list-title">
              <Col span={4}>
                <h5>Tên</h5>
              </Col>
              <Col span={2} className="centered">
                <h5>Tuổi</h5>
              </Col>
              <Col span={3}>
                <h5>BHYT</h5>
              </Col>
              <Col span={4}>
                <h5>Hình thức</h5>
              </Col>
              <Col span={4} className="centered">
                <h5>Giờ khám/STT</h5>
              </Col>
              <Col span={4} >
                <h5>Thanh toán</h5>
              </Col>
              <Col span={2} className="centered" >
                <h5>Trạng thái</h5>
              </Col>
            </Row>
            {isLoading ? (
              <div className="appt-view-loading">
                Đang tải lịch hẹn.
                <br />
                Xin vui lòng đợi chút...
              </div>
            ) : (
              apptListView
            )}
          </div>

        </Col>
        <Col className="appt-view-date-picker" span={8}>
          {/* date picker */}
          <Calendar
            fullscreen={false}
            onChange={(raw) => {
              if (raw != null) {
                const date = moment([raw.year(), raw.month(), raw.date()]);
                console.log(date.valueOf());
                // set selected date
                setSelDate(date);

                // load appointments by selected dat
                void loadAppointmentsByDate(date);
              }
            }} />
          <p className="context-info">
            Chọn một ngày để xem chi tiết lịch hẹn trong ngày đó.
          </p>
          <ServiceMgr deptId={deptId} />
        </Col>
      </Row>
    </Col >
  );
};
