import moment from 'moment';

/**
 * Some extra helper function for the sake of datetime clarity throughout the
 * project.
 *
 * READ THIS IF YOU'RE CONFUSED AF RIGHT NOW WHY SOME OF THIS METHOD APPEARS
 * AS `is not a function`: Using the native `moment.Moment` function returns a
 * native `moment.Moment`, while creating a `moment.Moment` in this particular
 * project file only will create a modified `moment.Moment`, loaded with the
 * below functions. So, if you're wondering why something like
 *
 * ```
 * moment().startOf('day').getvalueOf()
 *            ^^^                     ^^^
 *           native                 modified
 * ```
 *
 * throws `is not a function`, then you know why. To solve this, you should
 * wrap the native `moment.Moment` around a modified `moment()` constructor,
 * then you can use the modified functions. Something like this
 * ```
 * moment(moment().startOf('day')).getvalueOf()
 *  ^^^
 * wrapper
 * ```
 *
 * Hope this helps, because this tooks me half a day to figure this out.
 * Dum-dum counter: 0 <- update this every time you're pissed off by this.
 */
declare module 'moment' {
  export interface Moment {
    /**
     * Returns {@link this} as a date time string in the form of
     * DD/MM/YYYY, HH:mm:SS.
     *
     * @returns Date time string in the form of HH:mm, DD/MM/YYYY
     */
    toDateTimeString: () => string;
    /**
     * Returns {@link this} as a date string in the form of DD/MM/YYYY.
     *
     * @returns Date string in the form of DD/MM/YYYY
     */
    toDateString: () => string;

    /**
     * Returns {@link this} as a time string in the form of HH:mm.
     *
     * @returns Time string in the form of HH:mm
    */
    toTimeString: () => string;

    /**
     * Returns {@link this} as a time string in the form of HH:mm:ss
     *
     * @returns Time string in the form of HH:mm:ss
     */
    toTimeWithSecondString: () => string;

    /**
     * A helper to create a formatted string for a week span
     * this will have the form 'tuần 2/13/2023 - 2/17/2023'
     *
     * @returns a formatted string of a week span
     */
    toWeekSpanString: () => string;

    /**
     * Vietnam week starts on Mon, but `moment.startOf('week')` starts on Sun,
     * so we need to put `moment.startOf('isoWeek')` instead. This can be
     * confusing, so I put this here so it will be consistent for subsequent
     * developers.
     *
     * @returns start of the week (Mon) in which {@link this} is in
     */
    startOfWeek: () => Moment;

    /**
     * Vietnam week ends on Sun, but `moment.startOf('week')` ends on Sat, so we
     * need to put `moment.startOf('isoWeek')` instead. This can be confusing,
     * so I put this here so it will be consistent for subsequent developers.
     *
     * @returns end of the week (Sun) in which the given {@link this} is in
     */
    endOfWeek: () => Moment;

    /**
     * @returns the start of the day of {@link this}
     */
    startOfDay: () => Moment;

    /**
     * @returns the end of the day of {@link this}
     */
    endOfDay: () => Moment;
  }
}

moment.fn.toDateTimeString = function () {
  return this.format('DD/MM/YYYY, HH:mm:ss');
}

moment.fn.toDateString = function () {
  return this.format('DD/MM/YYYY');
}

moment.fn.toTimeString = function () {
  return this.format('HH:mm');
}

moment.fn.toTimeWithSecondString = function () {
  return this.format('HH:mm:ss');
}

moment.fn.toWeekSpanString = function () {
  const mon = this.startOfWeek().toDateString();
  const sun = this.endOfWeek().toDateString();
  return `tuần ${mon} - ${sun}`;
}

moment.fn.startOfWeek = function () {
  return moment(this).startOf('isoWeek');
}

moment.fn.endOfWeek = function () {
  return moment(this).endOf('isoWeek');
}

moment.fn.startOfDay = function () {
  return moment(this).startOf('day');
}

moment.fn.endOfDay = function () {
  return moment(this).endOf('day');
}

export default moment;
