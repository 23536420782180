import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface Login {
  email: string;
  password: string;
}

export const LoginKey: { [P in keyof Login]: P } = {
  email: 'email',
  password: 'password',
};

export const loginSchema: yup.SchemaOf<Login> = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const useLoginForm = () => {
  return useForm<Login>({ resolver: yupResolver(loginSchema) });
};
