
export const DEPT_DB = 'departments';
export const APPT_DB = 'appointment';
export const DOCTOR_DB = 'doctors';
export const HOSPITAL_DB = 'hospitals';
export const PAYMENT_DB = 'payment';
export const PROFILES_DB = 'profiles';
export const USERS_DB = 'users';
export const WEEKLY_ASM_DB = 'weekly-assignments';
export const SCHEDULE_DB = 'schedules';
export const SERVICES_DB = 'services';
