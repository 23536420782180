import { Col, Row } from 'antd';
import { type FC } from 'react';
import CDSidebar from '../../component/CDSidebar';
import Summary from '../../component/Summary';
import './_Dashboard.scss';

export const Dashboard: FC = () => {
  return (
    <main className="dashboard-root dashboard-inner">
      <Row>
        <Col sm={8} lg={6} xl={3}>
          <CDSidebar />
        </Col>
        <Col sm={16} lg={18} xl={21}>
          <Summary />
        </Col>
      </Row>
    </main>
  );
};
